<template>
    <div>
        <coverImage
        title="Build Memories"
        slogan="Find the right trip with the right people"
        image="bg.jpg"
        >
          <template v-slot:searchForms>
              <v-card v-if="currentForm" class="search-forms pa-3" tile min-height="250" elevation="1" width="100%">
                <v-card-title class="d-flex justify-space-between align-center">
                  <h1 class="greet mb-3 primary--text text-bold">Good {{ getCurrentTime() }}!</h1>
                  <v-btn icon color="primary" large @click="$store.dispatch('setCurrentForm', '')"><v-icon>mdi-close</v-icon></v-btn>
                </v-card-title>
                <flightsSearchForm v-show="currentForm === 'flights'"/>
                <carsSearchForm v-show="currentForm === 'cars'" class="mt-10" />
                <staysSearchForm v-show="currentForm === 'stays'" />
              </v-card>
          </template>
        </coverImage>

        <!-- home sections -->
        <!-- <v-container>
            <v-card tile min-height="200" elevation="1" width="100%"><v-card-text>heello</v-card-text></v-card>
        </v-container> -->
        <!-- <div class="main"> -->
        <v-container class="mt-0 pt-0">
            <!-- booking forms -->

            <explore />

            <bookings />

            <friends />

            <memories />

            <packages />
        </v-container>

        <services />

        <v-container>
            <around-the-world />
        </v-container>

        <subscribe />
        <!-- </div> -->
    </div>
</template>

<script>
import explore from '@/components/home_sections/explore'
import bookings from '@/components/home_sections/bookings'
import services from '@/components/home_sections/services'
import packages from '@/components/home_sections/packages'
import memories from '@/components/home_sections/memories'
import subscribe from '@/components/home_sections/subscribe.vue'
import friends from '@/components/home_sections/friends.vue'
import AroundTheWorld from '@/components/home_sections/aroundTheWorld.vue'
import coverImage from '@/components/static_sections/cover-image.vue'
import flightsSearchForm from '@/components/flights/searchForm'
import carsSearchForm from '@/components/cars/searchForm'
import staysSearchForm from '@/components/stays/searchForm'
import { mapState } from 'vuex'
// import Booking from './flights/booking.vue'

export default {
  components: { explore, services, bookings, packages, memories, subscribe, friends, AroundTheWorld, coverImage, flightsSearchForm, carsSearchForm, staysSearchForm },
  computed: {
    ...mapState(['currentForm'])
  }
}
</script>

<style>
.search-forms {
    z-index: 1;
}
.greet {
    font-size: 41px;
}
</style>
