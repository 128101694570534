<template>
    <div class="my-15">
        <h2 class="primary--text text-center mb-10">Your Trips Bookings</h2>
        <v-row justify="center" v-if="bookings.length">
            <v-col cols="12" sm="6" md="4" v-for="item in bookings" :key="item.id">
                <v-card
                max-height=""
                class="mr-5"
                @click="$store.dispatch('setTripBooking', item); $store.dispatch('setAgencyTrip', item.trip); $router.push({name: 'tripBooking', params: {id: item.id}});"
                >
                    <v-img
                    :src="item.trip.image"
                    lazy-src="https://picsum.photos/id/11/10/6"
                    height="200"
                    ></v-img>

                    <v-card-title class="paragraph--text" v-text="item.trip.name"></v-card-title>

                    <v-card-text>
                        <div class="mx-auto">
                            <v-icon>mdi-map</v-icon>
                            <span v-text="item.trip.city"></span>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12">
              <v-btn v-if="$route.name === 'Home'" @click="$router.push({path: '/profile/bookings'})" elevation="0" color="primary" x-large class="rounded-lg d-block mx-auto">show more</v-btn>
            </v-col>
        </v-row>
        <div v-else class="text-center primary--text">
          <v-img class="mx-auto" width="200" src="../../assets/empty.svg"></v-img>
          No trips bookings yet! Come and have fun
        </div>
    </div>
</template>

<script>
import user from '@/web_services/user'

export default {
  data () {
    return {
      bookings: [],
      loading: true
    }
  },
  methods: {},
  created () {
    let listLength = ''
    if (this.$route.name === 'Home') listLength = 4
    user.tripBookings(listLength).then(res => {
      this.bookings = res.data.data
    }).catch(() => {
      this.bookings = []
    }).finally(() => {
      this.loading = false
    })
  }
}
</script>
