<template>
    <v-img cover height="450" min-width="100%" :src="require(`@/assets/images/${image}`)"
    :class="$route.name === 'Home' ? 'bg' : ''"
    gradient="to top right, rgba(112,112,112,.52), rgba(112,112,112,.52)"
    >
        <!--  -->
        <slot name="addButton"></slot>
        <v-container>
            <v-row class="landing-row pb-0 px-1" align="center" justify="center">
                <div class="mx-auto text-center white--text">
                    <h1 class="heading text-bold">{{title}}</h1>
                    <p class="brief d-none d-md-block mx-auto" v-if="slogan">{{slogan}}</p>
                    <!-- <v-btn @click="$router.push({ path: '/plan-trip' })" elevation="0" color="primary" class="py-0 px-7 font-weight-regular ml-3 rounded-lg">create trip</v-btn> -->
                </div>
                <!-- <slot name="searchForms"></slot> -->
            </v-row>
        </v-container>
    </v-img>
</template>

<script>
export default {
  props: ['title', 'slogan', 'image']
}
</script>

<style>
.landing-row {
    min-height: 450px;
}
.landing-row.form {
    min-height: 65vh;
}
.brief {
    max-width: 60%;
    font-size: 20px;
}
</style>
