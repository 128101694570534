import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "home-about" */ '../views/AboutGT.vue')
  },
  {
    path: '/help',
    name: 'help',
    component: () => import(/* webpackChunkName: "help" */ '../views/help/help.vue')
  },
  {
    path: '/trips',
    name: 'userTrips',
    component: () => import(/* webpackChunkName: "trips" */ '../views/trips/trips.vue')
  },
  {
    path: '/trips/:id',
    name: 'tripDetails',
    component: () => import(/* webpackChunkName: "trip" */ '../views/trips/tripDetails.vue')
  },
  {
    path: '/trips/announce/:id',
    name: 'tripAnnounce',
    component: () => import(/* webpackChunkName: "trip" */ '../views/trips/tripDetails.vue')
  },
  {
    path: '/trips/:id/book',
    name: 'tripBook',
    component: () => import(/* webpackChunkName: "trip_booking" */ '../views/trips/booking.vue')
  },
  {
    path: '/trips/:id/bookingDetails',
    name: 'tripBooking',
    component: () => import(/* webpackChunkName: "trip_booking_details" */ '../views/trips/tripDetails.vue')
  },
  {
    path: '/trips/:id/memory',
    name: 'memory',
    component: () => import(/* webpackChunkName: "trip_memory" */ '../views/trips/tripDetails.vue')
  },
  {
    path: '/friends/:id',
    name: 'friendProfile',
    component: () => import(/* webpackChunkName: "friend_profile" */ '../views/friendProfile.vue')
  },

  // admin
  {
    path: '/admin',
    component: () => import('../views/admin/main.vue'),
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "admin-welcome" */ '../views/admin/welcome.vue')
      },
      {
        path: 'preferences',
        name: 'admin_preferences',
        component: () => import(/* webpackChunkName: "admin preferences questions" */ '../components/admin/prefquestions.vue')
      },
      {
        path: 'users',
        name: 'adminUsers',
        component: () => import(/* webpackChunkName: "admin-users" */ '../components/admin/users.vue')
      },
      {
        path: 'interests',
        name: 'admin_interests',
        component: () => import(/* webpackChunkName: "admin interest questions" */ '../components/admin/interestquestions.vue')
      },
      {
        path: 'interests/:action',
        name: 'interestsControl',
        component: () => import(/* webpackChunkName: "interest questions" */ '../views/admin/interestsForm.vue')
      },
      {
        path: 'agencies',
        name: 'adminAgencies',
        component: () => import(/* webpackChunkName: "interest questions" */ '../views/admin/agencies.vue')
      },
      {
        path: 'agencies/:id',
        name: 'agencyDetails',
        component: () => import(/* webpackChunkName: "interest questions" */ '../views/admin/agency.vue')
      },
      {
        path: 'agencies/:agencyId/trips/:id',
        name: 'adminAgencyTripDetails',
        component: () => import(/* webpackChunkName: "tripview" */ '../views/vendors/tripDetails.vue')
      },
      {
        path: 'tags',
        name: 'adminTags',
        component: () => import(/* webpackChunkName: "tags" */ '../views/admin/tags.vue')
      },
      {
        path: 'tags/:link',
        name: 'tagsView',
        component: () => import(/* webpackChunkName: "tagsview" */ '../views/admin/tagsView.vue')
      }
    ]
  },

  {
    path: '/chat/:id',
    name: 'chat',
    component: () => import(/* webpackChunkName: "chat" */ '../components/chats/chat.vue')
  },

  // user screens
  {
    path: '/profile',
    component: () => import('../views/profile/main.vue'),
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "auth" */ '../components/profile/about.vue')
      },
      {
        path: 'about',
        name: 'about',
        component: () => import(/* webpackChunkName: "about" */ '../components/profile/about.vue')
      },
      {
        path: 'security',
        name: 'security',
        component: () => import(/* webpackChunkName: "security" */ '../components/profile/security.vue')
      },
      {
        path: 'others',
        name: 'others',
        component: () => import(/* webpackChunkName: "others" */ '../components/profile/others.vue')
      },
      {
        path: 'wishlist',
        name: 'wishlist',
        component: () => import(/* webpackChunkName: "wishlist" */ '../components/profile/wishList.vue')
      },
      {
        path: 'bookings',
        name: 'bookings',
        component: () => import(/* webpackChunkName: "bookings" */ '../components/profile/bookings.vue')
      },
      {
        path: 'mytrips',
        name: 'mytrips',
        component: () => import(/* webpackChunkName: "mytrips" */ '../components/profile/Trips.vue')
      },
      {
        path: 'memories',
        name: 'memories',
        component: () => import(/* webpackChunkName: "mytrips" */ '../components/profile/memories.vue')
      },
      {
        path: 'preferences',
        name: 'preferences',
        component: () => import(/* webpackChunkName: "preferences" */ '../components/profile/prefrences.vue')
      },
      {
        path: 'social',
        name: 'social',
        component: () => import(/* webpackChunkName: "social" */ '../components/profile/social.vue')
      },
      {
        path: 'interests',
        name: 'interests',
        component: () => import(/* webpackChunkName: "interests" */ '../components/profile/interests.vue')
      },
      {
        path: 'delete',
        name: 'delete',
        component: () => import(/* webpackChunkName: "deletion" */ '../components/profile/delete.vue')
      }
    ]
  },

  // auth
  {
    path: '/auth',
    // name: 'auth',
    component: () => import(/* webpackChunkName: "auth_main" */ '../views/auth/main.vue'),
    children: [
      // main sub route
      {
        path: '',
        component: () => import(/* webpackChunkName: "auth" */ '../components/auth/login.vue')
      },
      // other subroutes
      {
        path: 'login',
        name: 'login',
        component: () => import(/* webpackChunkName: "auth_login" */ '../components/auth/login.vue')
      },
      {
        path: 'register',
        name: 'register',
        component: () => import(/* webpackChunkName: "auth_reg" */ '../components/auth/register.vue')
      },
      {
        path: 'reset-password',
        name: 'resetPassword',
        component: () => import(/* webpackChunkName: "auth_reset" */ '../components/auth/resetPass.vue')
      },
      {
        path: 'forgot-password',
        name: 'forgotPassword',
        component: () => import(/* webpackChunkName: "auth_forgot" */ '../components/auth/forgotPass.vue')
      },
      {
        path: 'verify',
        name: 'verify',
        component: () => import(/* webpackChunkName: "auth_ver" */ '../components/auth/verify.vue')
      }
    ]
  },

  // trip planner routes
  {
    path: '/plan-trip',
    // name: 'planTrip',
    component: () => import(/* webpackChunkName: "plan_trip_main" */ '../views/plan_trip/main.vue'),
    children: [
      // main sub route
      {
        path: '',
        component: () => import(/* webpackChunkName: "plan_trip" */ '../views/plan_trip/plansList.vue')
      },
      // other subroutes
      {
        path: 'trip-list',
        name: 'plansList',
        component: () => import(/* webpackChunkName: "plan_trip_list" */ '../views/plan_trip/plansList.vue')
      },
      {
        path: 'plan-view/:id',
        name: 'plan-view',
        component: () => import(/* webpackChunkName: "plan_trip_view" */ '../views/plan_trip/viewPlan.vue'),
        props: true
      },
      {
        path: 'plan-announce/:id',
        name: 'plan-announce',
        component: () => import(/* webpackChunkName: "plan_trip_announce" */ '../views/plan_trip/viewPlan.vue'),
        props: true
      },
      {
        path: 'plan-add',
        name: 'planAdd',
        component: () => import(/* webpackChunkName: "plan_trip_add" */ '../views/plan_trip/addPlan.vue')
      }
    ]
  },
  {
    path: '/quickPlanner',
    name: 'quickPlanner',
    component: () => import(/* webpackChunkName: "quick-planner" */ '../views/plan_trip/quickPlanner.vue')
  },
  {
    path: '/quickPlanner/booking/:id',
    name: 'plannerBook',
    component: () => import(/* webpackChunkName: "quick-planner" */ '../views/plan_trip/plannerBook.vue')
  },
  // policies

  // agency screens
  {
    path: '/vendors',
    component: () => import('../views/vendors/main.vue'),
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "auth" */ '../views/vendors/welcome.vue')
      },
      {
        path: 'finance',
        name: 'finance',
        component: () => import(/* webpackChunkName: "auth_login" */ '../views/vendors/finance.vue')
      },
      {
        path: 'profile',
        name: 'agencyProfile',
        component: () => import(/* webpackChunkName: "auth_login" */ '../views/vendors/profile.vue')
      },
      {
        path: 'trips',
        name: 'agencyTrips',
        component: () => import(/* webpackChunkName: "auth_login" */ '../views/vendors/trips.vue')
      },
      {
        path: 'trips/:action',
        name: 'addAgencyTrip',
        component: () => import(/* webpackChunkName: "auth_login" */ '../views/vendors/tripForm.vue')
      },
      {
        path: 'trips/:id/details',
        name: 'agencyTripDetails',
        component: () => import(/* webpackChunkName: "auth_login" */ '../views/vendors/tripDetails.vue')
      },
      {
        path: 'bookings',
        name: 'agencyBookings',
        component: () => import(/* webpackChunkName: "auth_login" */ '../views/vendors/bookings.vue')
      },
      {
        path: 'bookings/booking-details/:id',
        name: 'agencyBookingDetails',
        component: () => import(/* webpackChunkName: "auth_login" */ '../views/vendors/bookingDetails.vue')
      }
    ]
  },

  {
    path: '/privacy',
    // name: 'policy',
    component: () => import(/* webpackChunkName: "policy" */ '../views/policies/privacy.vue')
  },
  {
    path: '/terms',
    // name: 'terms',
    component: () => import(/* webpackChunkName: "terms" */ '../views/policies/terms.vue')
  },
  // flights
  {
    path: '/flights',
    name: 'flights',
    component: () => import(/* webpackChunkName: "fligth_results" */ '../views/flights/flights.vue')
  },
  {
    path: '/flights/results',
    name: 'flightResults',
    component: () => import(/* webpackChunkName: "fligth_results" */ '../views/flights/results.vue')
  },
  {
    path: '/flights/booking',
    name: 'book',
    component: () => import(/* webpackChunkName: "flight_booking" */ '../views/flights/booking.vue')
  },

  // cars
  {
    path: '/cars',
    name: 'cars',
    component: () => import(/* webpackChunkName: "cars" */ '../views/cars/cars.vue')
  },
  {
    path: '/cars/results',
    name: 'carsResults',
    component: () => import(/* webpackChunkName: "car_reusults" */ '../views/cars/results.vue')
  },
  {
    path: '/cars/booking',
    name: 'carsBook',
    component: () => import(/* webpackChunkName: "car_reusults" */ '../views/cars/book.vue')
  },
  // stays
  {
    path: '/stays',
    name: 'stays',
    component: () => import(/* webpackChunkName: "hotels" */ '../views/stays/stays.vue')
  },
  {
    path: '/stays/results',
    name: 'hotelsResults',
    component: () => import(/* webpackChunkName: "hotel_results" */ '../views/stays/hotelResults.vue')
  },
  {
    path: '/hotel/:sessionId/:hotelIndex/:hotelCode',
    name: 'oneHotel',
    component: () => import(/* webpackChunkName: "one_hotel" */'../views/stays/oneHotel.vue')
  },
  {
    path: '/bookhotel',
    name: 'hotelBook',
    component: () => import('../views/stays/hotelBook.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
  // scrollBehavior (to, from, savedPosition) {
  //   return { x: 0, y: 0 }
  // }
})

router.beforeEach((to, from, next) => {
  // if (!Vue.$cookies.isKey('gt_user_token') && !to.path.includes('/auth') && !to.path.includes('plan-announce')) next({ path: '/auth' })
  // else {
  //   next()
  // }
  const role = Vue.$cookies.get('gt_user_role')
  const authorized = Vue.$cookies.isKey('gt_user_token')
  if (authorized) {
    if (to.path.includes('/auth')) next({ path: '/' })
    else {
      switch (role) {
        case 'Agency':
          if (to.path.includes('/vendors')) next()
          else next({ path: '/vendors' })
          break
        case 'Admin':
          if (to.path.includes('/admin')) next()
          else next({ path: '/admin' })
          break
        default:
          if (!to.path.includes('/vendors') && !to.path.includes('/admin')) next()
          else next({ path: '/' })
          break
      }
    }
  } else {
    console.log('here', to.path.includes('/trips/announce'))
    if (to.path.includes('/auth') || to.path.includes('plan-announce') || to.path.includes('/trips/announce')) next()
    else next({ path: '/auth' })
  }
})

export default router
