<template>
    <div class="mb-15 mt-5">
        <v-row v-if="loading">
          <v-skeleton-loader
          v-for="n in 5"
          :key="n"
          class="mx-auto"
          width="300"
          type="card"
          ></v-skeleton-loader>
        </v-row>
        <v-row align="end" v-if="!loading">
            <v-col cols="4" sm="3">
                <div class="explore">
                    <h2 class="primary--text matching">Trips <br /> Matching you <br /></h2>

                    <!-- locations slide indicators -->
                    <div class="indicators">
                        <v-btn :disabled="disableScrollLeft" @click="scrollLeft" icon color="indigo"><v-icon large>mdi-chevron-left</v-icon></v-btn>
                        <v-btn :disabled="disableScrollRight" @click="scrollRight" icon color="indigo"><v-icon large>mdi-chevron-right</v-icon></v-btn>
                    </div>
                </div>
            </v-col>
            <v-col cols="8" sm="9" class="pr-0"
            v-touch="{
              left: () => swipe('Left'),
              right: () => swipe('Right'),
            }"
            >
                <div v-if="locations.length" class="d-flex items-slider" id="locations-slider">
                  <v-hover v-for="item in locations" :key="item.id">
                    <template v-slot:default="{ hover }">
                      <v-card
                      height="260"
                      elevation="0"
                      tile
                      >
                        <v-img class="pa-3" :src="item.image ? item.image : require('@/assets/images/bg.jpg')" height="260" width="220">
                          <h3 v-text="item.name" class="white--text"></h3>
                          <span class="white--text percentage text-bold">{{item.matching_percentage}}%</span>
                        </v-img>

                        <v-fade-transition>
                          <v-overlay
                          v-if="hover"
                          absolute
                          color="#FF8F95"
                          >
                            <v-btn small elevation="0" color="white indigo--text" @click="$store.dispatch('setAgencyTrip', item); $router.push({name: 'tripDetails', params: {id: item.id}});">view details</v-btn>
                          </v-overlay>
                        </v-fade-transition>
                      </v-card>
                    </template>
                  </v-hover>
                  <div class="d-flex" v-if="locations.length < 4">
                    <v-card
                      height="260"
                      elevation="0"
                      v-for="n in 4 - locations.length"
                      :key="n"
                      tile
                      >
                        <v-img class="pa-3" src="../../assets/images/bg.jpg" height="260" width="220"></v-img>
                      </v-card>
                    </div>
                </div>
                <p v-else class="text-center primary--text">No trips match your profile yet!</p>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import user from '@/web_services/user'

export default {
  data () {
    return {
      locations: [],
      disableScrollLeft: true,
      disableScrollRight: false,
      swipeDirection: 'Right',
      loading: true
    }
  },
  methods: {
    swipe (direction) {
      this.swipeDirection = direction
    },
    scrollLeft () {
      const table = document.getElementById('locations-slider')
      table.scrollLeft -= 250
      setInterval(() => {
        this.disableScrollLeft = table.scrollLeft === 0
        this.disableScrollRight = table.scrollLeft + table.offsetWidth >= table.scrollWidth
      }, 100)
    },
    scrollRight () {
      const table = document.getElementById('locations-slider')
      table.scrollLeft += 250
      setInterval(() => {
        this.disableScrollLeft = table.scrollLeft === 0
        this.disableScrollRight = table.scrollLeft + table.offsetWidth >= table.scrollWidth
      }, 100)
    }
  },
  created () {
    user.activeTrips().then(response => {
      this.locations = response.data.data.slice(0, 10)
    }).catch(() => {
      this.locations = []
    }).finally(() => {
      this.loading = false
    })
  }
}
</script>

<style scoped>
.explore {
    height: 260px;
    background: -webkit-image-set( url('../../assets/images/location-sm.png') 1x, url('../../assets/images/location-lg.png') 2x );
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left bottom;
    position: relative;
}
.explore h2 {
  font-size: 31px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%)
}
.indicators {
  position: absolute;
  bottom: 0;
  right: 0;
}
.percentage {
  position: absolute;
  bottom: 0;
  font-size: 18px;
}
@media screen and (max-width: 450px) {
    .heading {
        font-size: 18px;
    }
}
</style>
