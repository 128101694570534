<template>
    <div class="services my-10">
        <div class="bg-overlay py-10">
            <v-container>
                <v-row justify="center" align="center">
                    <v-col cols="12" sm="4" :class="$vuetify.breakpoint.smAndDown ? 'text-center service mb-5' : 'service'">
                        <v-icon x-large color="white">mdi-airplane</v-icon>
                        <h3 class="white--text">Flight Tickets</h3>
                        <p class="white--text">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        <v-btn color="white primary--text" @click="activateForm('flights')">Search Flights</v-btn>
                    </v-col>
                    <v-col cols="12" sm="4" :class="$vuetify.breakpoint.smAndDown ? 'text-center service mb-5' : 'service pl-10'">
                        <v-icon x-large color="white">mdi-bed</v-icon>
                        <h3 class="white--text">Hotels Reservations</h3>
                        <p class="white--text">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        <v-btn color="white primary--text" @click="activateForm('stays')">Search Stays</v-btn>
                    </v-col>
                    <v-col cols="12" sm="4" :class="$vuetify.breakpoint.smAndDown ? 'text-center' : ' pl-10'">
                        <v-icon x-large color="white">mdi-car</v-icon>
                        <h3 class="white--text">Transportations</h3>
                        <p class="white--text">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        <v-btn color="white primary--text" @click="activateForm('cars')">Search Cars</v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </div>
</template>

<script>
export default {
  methods: {
    activateForm (type) {
      this.$store.dispatch('setCurrentForm', type)
      window.scrollTo(0, 0)
    }
  }
}
</script>

<style>
.services {
    background: url('../../assets/images/sea.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}
.service {
    border-right: 0.5px solid white;
}
.bg-overlay {
    background-color: #0c0c0c14;
}
@media screen and (max-width: 600px) {
    .service {
        border: none;
    }
}
</style>
