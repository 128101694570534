import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#FF8F95',
        primaryDark: '#FF1572',
        paragraph: '#707070',
        subheaderGrey: '#818181',
        forms: '#cecece',
        pink: '#FF5AC7'
      }
    }
  }
})
