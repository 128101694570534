<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :close-on-click="true"
    bottom
    transition="scale-transition"
    min-width="300"
    offset-y
  >
    <template v-slot:activator="{ on }">
      <v-btn
        :color="$route.name === 'flights' ? 'primary' : 'white'"
        dark
        text
        v-on="on"
      >
        {{passengerInfo}}
        <v-icon right>mdi-chevron-down</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-text class="pb-0">
          <!-- <v-slider
            v-model="passengers.adults"
            color="secondary"
            track-color="grey"
            always-dirty
            label="adults"
            min="1"
            max="9"
            prepend-icon="mdi-minus-circle"
            append-icon="mdi-plus-circle"
            @click:append="++passengers.adults"
            @click:prepend="--passengers.adults"
            :tick-labels="[1,2,3,4,5,6,7,8,9]"
            tick-size="1"
          >
          </v-slider> -->
          <v-row class="pa-2" justify="space-between">
            <span class="body-2 black--text">Adults <span class="paragraph--text"> > 12</span></span>
            <div>
              <v-btn x-small color="primary lighten-3 primary--text" class="headline py-3 mx-2" elevation="0" @click="--passengers.adults" :disabled="passengers.adults <= 1">-</v-btn>
              <span>{{passengers.adults}}</span>
              <v-btn x-small color="primary lighten-3 primary--text" class="headline py-3 mx-2" elevation="0" @click="++passengers.adults" :disabled="passengers.adults >= 9">+</v-btn>
            </div>
          </v-row>

          <v-row class="pa-2" justify="space-between">
            <span class="body-2 black--text">Adults <span class="paragraph--text"> Above 12</span></span>
            <div>
              <v-btn x-small color="primary lighten-3 primary--text" class="headline py-3 mx-2" elevation="0" @click="--passengers.children" :disabled="passengers.children === 0">-</v-btn>
              <span>{{passengers.children}}</span>
              <v-btn x-small color="primary lighten-3 primary--text" class="headline py-3 mx-2" elevation="0" @click="++passengers.children" :disabled="passengers.adults + passengers.children >= 9">+</v-btn>
            </div>
          </v-row>

          <v-row class="pa-2" justify="space-between">
            <span class="body-2 black--text">Infants <span class="paragraph--text"> Under 2</span></span>
            <div>
              <v-btn x-small color="primary lighten-3 primary--text" class="headline py-3 mx-2" elevation="0" @click="--passengers.infants" :disabled="passengers.infants === 0">-</v-btn>
              <span>{{passengers.infants}}</span>
              <v-btn x-small color="primary lighten-3 primary--text" class="headline py-3 mx-2" elevation="0" @click="++passengers.infants" :disabled="passengers.infants === passengers.adults">+</v-btn>
            </div>
          </v-row>
          <!-- <v-slider
            v-model="passengers.children"
            color="secondary"
            track-color="grey"
            always-dirty
            label="Children"
            min="0"
            :max="children"
            prepend-icon="mdi-minus-circle"
            append-icon="mdi-plus-circle"
            @click:append="++passengers.children"
            @click:prepend="--passengers.children"
            :tick-labels="childrenLabels"
            tick-size="1"
            :disabled="childrenLabels.length === 1"
            >
          </v-slider> -->

          <!-- <v-slider
            v-model="passengers.infants"
            color="secondary"
            track-color="grey"
            label="infants"
            always-dirty
            min="0"
            :max="infants"
            prepend-icon="mdi-minus-circle"
            append-icon="mdi-plus-circle"
            @click:append="++passengers.infants"
            @click:prepend="--passengers.infants"
            :tick-labels="infantsLabels"
            tick-size="1"
            >
          </v-slider> -->
          <v-card-actions class="mt-2">
              <v-spacer></v-spacer>
              <v-btn text @click="menu = false" color="error">Cancel</v-btn>
              <v-btn color="success" text @click="save">Done</v-btn>
          </v-card-actions>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  data: () => ({
    menu: false,
    passengerInfo: '1 passenger, Economy',
    passengers: {
      adults: Number(1),
      children: 0,
      infants: 0
    }
  }),
  computed: {
    children () {
      const number = 9 - this.passengers.adults
      return Number(number)
    },
    childrenLabels () {
      const labels = []
      for (let i = 0; i <= this.children; i++) {
        labels.push(i)
      }
      return labels
    },
    infants () {
      const number = this.passengers.adults
      return Number(number)
    },
    infantsLabels () {
      const labels = []
      for (let i = 0; i <= this.passengers.adults; i++) {
        labels.push(i)
      }
      return labels
    }
  },
  watch: {
    'passengers.adults' (newVal, oldVal) {
      const limit = 9 - newVal
      if (oldVal > newVal) {
        if (this.passengers.infants > newVal) {
          this.passengers.infants -= 1
        }
      } else if (oldVal < newVal) {
        if (this.passengers.children > limit) {
          this.passengers.children -= 1
        }
      }
    }
  },
  methods: {
    save () {
      this.getLabel()
      this.$store.dispatch('setPassengersAdults', Number(this.passengers.adults))
      this.$store.dispatch('setPassengersChildren', Number(this.passengers.children))
      this.$store.dispatch('setPassengersInfants', Number(this.passengers.infants))
      this.menu = false
    },
    getLabel () {
      const passengerNumber = Number(this.passengers.adults) + Number(this.passengers.children) + Number(this.passengers.infants)
      this.passengerInfo = `${passengerNumber} passengers`
    }
  },
  created () {
    this.passengers = this.$store.state.passengers
    this.getLabel()
  }
}
</script>
