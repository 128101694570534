import Vue from 'vue'
import { baseUrl } from '../endpoints'

const timeout = 10000

export default {
  // user register
  userRegister (body) {
    return Vue.axios.post(baseUrl + 'user/register',
      body,
      {
        timeout: timeout,
        headers: { Accept: 'application/json', 'Content-type': 'application/json' }
      })
  },

  // agency register
  agencyRegister (body) {
    return Vue.axios.post(baseUrl + 'agency/register',
      body,
      {
        timeout: timeout,
        headers: { Accept: 'application/json', 'Content-type': 'application/json' }
      })
  },

  // send verification email
  async sendMail (body) {
    return Vue.axios.post(baseUrl + 'email/verify',
      body,
      {
        timeout: timeout,
        headers: { Accept: 'application/json', 'Content-type': 'application/json' }
      })
  },

  // verify email
  verify (body) {
    return Vue.axios.post(baseUrl + 'email/verify-email',
      body,
      {
        timeout: timeout,
        headers: { Accept: 'application/json', 'Content-type': 'application/json' }
      })
  },

  // user login
  userLogin (body) {
    // try {
    return Vue.axios.post(baseUrl + 'user/login',
      body,
      {
        timeout: timeout,
        headers: { Accept: 'application/json', 'Content-type': 'application/json' }
      })
  },

  // agency login
  agencyLogin (body) {
    // try {
    return Vue.axios.post(baseUrl + 'agency/login',
      body,
      {
        timeout: timeout,
        headers: { Accept: 'application/json', 'Content-type': 'application/json' }
      })
  },

  // logout
  logout () {
    return Vue.axios.get(baseUrl + 'user/logout',
      {
        timeout: timeout,
        data: {}
      })
  },

  // forgot password
  forgotPass (body) {
    return Vue.axios.post(baseUrl + 'email/reset-password',
      body,
      {
        timeout: timeout,
        headers: { Accept: 'application/json', 'Content-type': 'application/json' }
      })
  },

  // reset password
  resetPass (body) {
    return Vue.axios.post(baseUrl + 'email/reset-password-code',
      body,
      {
        timeout: timeout,
        headers: { Accept: 'application/json', 'Content-type': 'application/json' }
      })
  },
  facebook (data) {
    return Vue.axios.post(baseUrl + 'user/facebook/login',
      data,
      {
        timeout: timeout,
        headers: { Accept: 'application/json', 'Content-type': 'application/json' }
      })
  },
  google (data) {
    return Vue.axios.post(baseUrl + 'user/google/login',
      data,
      {
        timeout: timeout,
        headers: { Accept: 'application/json', 'Content-type': 'application/json' }
      })
  }
}
