export const initFbsdk = () => {
  return new Promise(resolve => {
    /* eslint-disable */
    window.fbAsyncInit = function () {
      FB.init({ appId: '653463462742861', cookie: true, xfbml: true, oauth: true });

      // *** here is my code ***
      if (typeof facebookInit == 'function') {
        facebookInit();
      }
    };

    (function(d){
      var js, id = 'facebook-jssdk'; if (d.getElementById(id)) {return;}
      js = d.createElement('script'); js.id = id; js.async = true;
      js.src = "//connect.facebook.net/en_US/all.js";
      d.getElementsByTagName('head')[0].appendChild(js);
    }(document));
  })
}
