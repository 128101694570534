<template>
    <div class="my-15">
        <h2 class="primary--text text-center mb-2">Look at your Visited Cities & Memories</h2>
        <p class="paragraph--text text-center mb-2">Have a look at your happy moments</p>
        <div class="text-center mb-5">
          <v-chip large color="#F5F5F5" v-for="(item, i) in cities" :key="i" class="ma-1 text-capitalize px-10 py-1 text-center">{{item}}</v-chip>
        </div>
        <v-row justify="center" v-if="!loading && memories.length">
          <v-col cols="12" sm="6" md="4" v-for="item in memories" :key="item.id">
            <v-card @click="$store.dispatch('setTripBooking', item); $store.dispatch('setAgencyTrip', item.trip); $router.push({name: 'memory', params: {id: item.id}});">
                <v-img
                :src="item.trip.image"
                lazy-src="https://picsum.photos/id/11/10/6"
                height="200"
                >
                </v-img>
                <v-card-text class="text-center">
                  <p class="text-h6">{{item.trip.city}}</p>
                  <p class="body-2">{{new Date(item.travel_date).toDateString()}}</p>
                </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-btn elevation="0" v-if="$route.name === 'Home'" @click="$router.push({path: '/profile/memories'})" color="primary" x-large class="rounded-lg d-block mx-auto">show more</v-btn>
          </v-col>
        </v-row>
        <div v-else class="text-center primary--text">
          <v-img class="mx-auto" width="200" src="../../assets/empty.svg"></v-img>
          No memories yet! Create Some
        </div>
    </div>
</template>

<script>
import user from '@/web_services/user'
// import main from '@/web_services/main'

export default {
  data () {
    return {
      memories: [],
      cities: ['Gouns', 'Aswan', 'Turkey', 'Dahab', 'maldives', 'sharm elsheikh', 'egypt', 'USA', 'istanbul'],
      loading: true
    }
  },
  methods: {},
  created () {
    user.userMemories(8).then(res => {
      this.memories = res.data.data.slice(0, 5)
    }).catch(() => {
      this.memories = []
    }).finally(() => {
      this.loading = false
    })
    // main.getCities().then(res => {
    //   this.cities = res.data.data
    // })
  }
}
</script>
