<template>
  <v-app>
    <navbar v-if="showHeaderAndFooter" />
    <v-main>
      <router-view/>
    </v-main>
    <footerBox class="footer" v-if="showHeaderAndFooter" />
  </v-app>
</template>

<script>
import navbar from '@/components/static_sections/navbar'
import footerBox from '@/components/static_sections/footer'
import user from '@/web_services/user'

export default {
  components: { navbar, footerBox },
  name: 'App',

  computed: {
    showHeaderAndFooter () {
      const route = this.$route.path
      return !route.includes('/auth') && !route.includes('/admin') && !route.includes('/vendors') && !route.includes('/help') && !route.includes('/privacy') && !route.includes('/terms')
    }
  },
  created () {
    // check if user data exists
    if (this.$cookies.isKey('gt_user_token') && !this.$store.state.user) {
      user.profile().then(res => {
        this.$store.dispatch('setUserData', res.data.data)
      }).catch(err => {
        if (err.response.status === 401) {
          this.$store.dispatch('removeUser')
          this.$cookies.remove('gt_user_token')
          this.$router.push({ path: '/auth' })
        }
      })
    }
  }
}
</script>
<style>
.auth .theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
  border-bottom: 1px solid #cecece;
}
.v-text-field input, .v-textarea textarea {
  font-size: 18px;
}
.v-card__text {
  font-size: 18px;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  font-size: 1.2rem !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: 1.1rem !important;
}
</style>
