import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    authTab: 0,

    // which booking form to show in home page
    currentForm: '',

    // user
    user: null,
    tripBooking: null,

    // flights state
    airports: {
      departureAirports: [null, null, null],
      arrivalAirports: [null, null, null]
    },
    flightDates: {
      departureDates: [null, null, null],
      arrivalDate: null
    },
    passengers: {
      adults: 1,
      children: 0,
      infants: 0,
      classType: { text: 'Economy', value: 'Y' }
    },
    preferredAirline: null,
    flightResults: {},
    flightPromotions: [],
    trip: null,
    flightType: 'round',
    flightsTimeOut: false,
    flightsTimeOutFunction: null,
    flightsSearchTimeOutFunction: null,
    writtenAddress: '',
    zipCode: '',
    isDirectFlights: false,

    // hotels state

    hotelTimeOut: false,
    hotelTimeOutFunction: null,
    hotelSearchTimeOutFunction: null,
    hotelSearch: {
      checkInDate: null,
      checkOutDate: null,
      destination: null,
      guestsInfo: [
        {
          adults: 1,
          children: 0,
          childrenAges: []
        }
      ]
    },
    countryCode: null,
    guestData: null,
    starRating: '',
    hotelStartLocation: '',
    hotels: null,

    // cars state
    carInfo: {
      pickupLocation: null,
      dropLocation: null,
      pickup: new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().substring(0, 10),
      drop: new Date(new Date().setDate(new Date().getDate() + 2)).toISOString().substring(0, 10),
      pickupTime: '00:00',
      dropTime: '00:30'
    },
    cars: null,
    carsTimeOut: false,
    carsTimeOutFunction: null,
    carsSearchTimeOutFunction: null,

    // agency
    agencyTrip: {},
    agency: {}
  },
  mutations: {
    setAuthTab (state, val) {
      state.authTab = val
    },

    setCurrentForm (state, form) {
      state.currentForm = form
    },
    // user mutation
    setUserData (state, payload) {
      state.user = payload
    },
    removeUser (state) {
      state.user = null
    },
    setTripBooking (state, payload) {
      state.tripBooking = payload
    },

    // hotels mutation
    setHotelDestination (state, payload) {
      state.hotelSearch.destination = payload
    },
    setHotels (state, payload) {
      state.hotels = payload
    },
    removeHotelsResults (state) {
      state.hotels = null
    },
    setHotelSearchTimeoutFunction (state, payload) {
      state.hotelSearchTimeOutFunction = payload
    },
    setHotelCheckInDate (state, payload) {
      state.hotelSearch.checkInDate = payload
    },
    setHotelGuestsInfo (state, payload) {
      state.hotelSearch.guestsInfo = payload
    },
    setHotelCheckOutDate (state, payload) {
      state.hotelSearch.checkOutDate = payload
    },
    setHotelTimeOut (state, payload) {
      state.hotelTimeOut = payload
    },
    setHotelTimeOutFunction (state, payload) {
      state.hotelTimeOutFunction = payload
    },
    setCountryCode (state, payload) {
      state.countryCode = payload
    },
    setStarRating (state, payload) {
      state.starRating = payload
    },

    // flights mutations
    setAirports (state, payload) {
      switch (payload.type) {
        case 'departure1':
          state.airports.departureAirports[0] = payload.data
          break
        case 'departure2':
          state.airports.departureAirports[1] = payload.data
          break
        case 'departure3':
          state.airports.departureAirports[2] = payload.data
          break
        case 'arrival1':
          state.airports.arrivalAirports[0] = payload.data
          break
        case 'arrival2':
          state.airports.arrivalAirports[1] = payload.data
          break
        case 'arrival3':
          state.airports.arrivalAirports[2] = payload.data
          break
        case 'carPickup':
          state.carInfo.pickupLocation = payload.data
          break
        case 'carDrop':
          state.carInfo.dropLocation = payload.data
          break
        default:
          break
      }
    },
    setDates (state, payload) {
      switch (payload.type) {
        case 'departure1':
          state.flightDates.departureDates[0] = payload.data
          break
        case 'departure2':
          state.flightDates.departureDates[1] = payload.data
          break
        case 'departure3':
          state.flightDates.departureDates[2] = payload.data
          break
        case 'arrival':
          state.flightDates.arrivalDate = payload.data
          break
        case 'pickup':
          state.carInfo.pickup = payload.data
          break
        case 'drop':
          state.carInfo.drop = payload.data
          break
        default:
          break
      }
    },
    setPreferredAirline (state, payload) {
      state.preferredAirline = payload
    },
    setPassengersAdults (state, payload) {
      state.passengers.adults = payload
    },
    setPassengersChildren (state, payload) {
      state.passengers.children = payload
    },
    setPassengersInfants (state, payload) {
      state.passengers.infants = payload
    },
    setPassengersClass (state, payload) {
      state.passengers.classType = payload
    },
    setFlightResults (state, payload) {
      state.flightResults = payload
    },
    setFlightsMinPrice (state, payload) {
      state.flightResults.minPrice = payload
    },
    setFlightsMaxPrice (state, payload) {
      state.flightResults.maxPrice = payload
    },
    setFlightsMinStopsDuration (state, payload) {
      state.flightResults.minStopsDuration = payload
    },
    setFlightsMaxStopsDuration (state, payload) {
      state.flightResults.maxStopsDuration = payload
    },
    setAltAirlines (state, payload) {
      if (state.flightResults.airlines.length > 0) {
        payload.forEach(item => {
          state.flightResults.airlines.push(item)
        })
      } else {
        payload.forEach(item => {
          state.flightResults.airlines.push(item)
        })
      }
    },
    updateFlights (state, payload) {
      state.flightResults.flights = payload
    },
    setFlightPromotions (state, payload) {
      state.flightPromotions = payload
    },
    setFlightsTimeOut (state, payload) {
      state.flightsTimeOut = payload
    },
    setFlightsTimeOutFunction (state, payload) {
      state.flightsTimeOutFunction = payload
    },
    setFlightsSearchTimeoutFunction (state, payload) {
      state.flightsSearchTimeOutFunction = payload
    },
    setType (state, payload) {
      state.flightType = payload
    },
    setDirectFlights (state, payload) {
      state.isDirectFlights = payload
    },
    setTravellersData (state, payload) {
      state.travellersData = payload
    },
    setWrittenAddress (state, payload) {
      state.writtenAddress = payload
    },
    setZipCode (state, payload) {
      state.zipCode = payload
    },
    removeSegment (state) {
      state.airports.departureAirports[2] = null
      state.airports.arrivalAirports[2] = null
      state.flightDates.departureDates[2] = null
    },
    removeFlightResults (state) {
      state.flightResults = {}
    },
    clearFlightsTimeoutFunction (state) {
      clearTimeout(state.flightsTimeOutFunction)
    },
    clearFlightsSearchTimeoutFunction (state) {
      clearTimeout(state.flightsSearchTimeOutFunction)
    },

    // cars mutations
    setCarTimes  (state, payload) {
      payload.type === 'Pickup Time' ? state.carInfo.pickupTime = payload.data : state.carInfo.dropTime = payload.data
    },
    setCars (state, payload) {
      state.cars = payload
    },
    setCarsTimeOut (state, payload) {
      state.carsTimeOut = payload
    },
    setCarsTimeOutFunction (state, payload) {
      state.carsTimeOutFunction = payload
    },
    setCarsSearchTimeOutFunction (state, payload) {
      state.carsSearchTimeOutFunction = payload
    },
    clearDropLocation (state) {
      state.carInfo.dropLocation = null
    },
    clearCarsTimeOutFunction (state) {
      clearTimeout(state.carsTimeOutFunction)
    },
    clearCarsSearchTimeOutFunction (state) {
      clearTimeout(state.carsSearchTimeOutFunction)
    },
    removeCarsResults (state) {
      state.cars = null
    },

    // agency
    setAgencyTrip (state, trip) {
      state.agencyTrip = trip
    },
    setAgency (state, agency) {
      state.agency = agency
    }
  },
  actions: {
    setAuthTab ({ commit }, val) {
      commit('setAuthTab', val)
    },

    setCurrentForm ({ commit }, form) {
      commit('setCurrentForm', form)
    },
    // user actions
    setUserData ({ commit }, user) {
      commit('setUserData', user)
    },
    removeUser ({ commit }) {
      commit('removeUser')
    },
    setTripBooking ({ commit }, payload) {
      commit('setTripBooking', payload)
    },

    // flights actions
    setAirports (context, payload) {
      context.commit('setAirports', payload)
    },
    setDates (context, payload) {
      // payload.data = new Date(payload.data).toLocaleString().substring(0, 10)
      context.commit('setDates', payload)
    },
    setPreferredAirline (context, payload) {
      context.commit('setPreferredAirline', payload)
    },
    setPassengersAdults (context, payload) {
      context.commit('setPassengersAdults', payload)
    },
    setPassengersChildren (context, payload) {
      context.commit('setPassengersChildren', payload)
    },
    setPassengersInfants (context, payload) {
      context.commit('setPassengersInfants', payload)
    },
    setPassengersClass (context, payload) {
      context.commit('setPassengersClass', payload)
    },
    setFlightResults (context, payload) {
      context.commit('setFlightResults', payload)
    },
    setFlightsMinPrice (context, payload) {
      context.commit('setFlightsMinPrice', payload)
    },
    setFlightsMaxPrice (context, payload) {
      context.commit('setFlightsMaxPrice', payload)
    },
    setFlightsMinStopsDuration (context, payload) {
      context.commit('setFlightsMinStopsDuration', payload)
    },
    setFlightsMaxStopsDuration (context, payload) {
      context.commit('setFlightsMaxStopsDuration', payload)
    },
    setAltAirlines (context, payload) {
      context.commit('setAltAirlines', payload)
    },
    updateFlights (context, payload) {
      context.commit('updateFlights', payload)
    },
    setType (context, payload) {
      context.commit('setType', payload)
    },
    setTravellersData (context, payload) {
      context.commit('setTravellersData', payload)
    },
    setWrittenAddress (context, payload) {
      context.commit('setWrittenAddress', payload)
    },
    setHotelGuestsInfo (context, payload) {
      context.commit('setHotelGuestsInfo', payload)
    },
    setZipCode (context, payload) {
      context.commit('setZipCode', payload)
    },
    setFlightsTimeOut (context, payload) {
      context.commit('setFlightsTimeOut', payload)
    },
    setFlightsTimeOutFunction (context, payload) {
      context.commit('setFlightsTimeOutFunction', payload)
    },
    setFlightsSearchTimeoutFunction (context, payload) {
      context.commit('setFlightsSearchTimeoutFunction', payload)
    },
    removeAirportsData (context) {
      context.commit('removeAirportsData')
    },
    removeDatesData (context) {
      context.commit('removeDatesData')
    },
    removeSegment (context) {
      context.commit('removeSegment')
    },
    removePassengersData (context) {
      context.commit('removePassengersData')
    },
    removeFlightResults (context) {
      context.commit('removeFlightResults')
    },
    clearFlightsTimeoutFunction (context) {
      context.commit('clearFlightsTimeoutFunction')
    },
    clearFlightsSearchTimeoutFunction (context) {
      context.commit('clearFlightsSearchTimeoutFunction')
    },
    // hotels section
    clearHotelGuests (state) {
      state.guestsInfo = [
        {
          adults: 1,
          children: 0,
          childrenAges: []
        }
      ]
    },
    removeHotelsResults (context) {
      context.commit('removeHotelsResults')
    },
    setHotelSearchTimeoutFunction (context, payload) {
      context.commit('setHotelSearchTimeoutFunction', payload)
    },
    clearHotelTimeoutFunction (state) {
      clearTimeout(state.hotelTimeOutFunction)
    },
    clearHotelSearchTimeoutFunction (state) {
      clearTimeout(state.hotelSearchTimeOutFunction)
    },
    setCountryCode (context, payload) {
      context.commit('setCountryCode', payload)
    },
    setHotelTimeOut (context, payload) {
      context.commit('setHotelTimeOut', payload)
    },
    setHotels (context, payload) {
      context.commit('setHotels', payload)
    },
    setHotelTimeOutFunction (context, payload) {
      context.commit('setHotelTimeOutFunction', payload)
    },
    removeCountryCode (state) {
      state.countryCode = null
    },
    setGuestData (state, payload) {
      state.guestData = payload
    },
    setHotelDestination (context, payload) {
      context.commit('setHotelDestination', payload)
    },
    setHotelCheckInDate (context, payload) {
      context.commit('setHotelCheckInDate', payload)
    },
    setHotelCheckOutDate (context, payload) {
      context.commit('setHotelCheckOutDate', payload)
    },
    removeGuestData (state) {
      state.guestData = null
    },
    setStarRating (context, payload) {
      context.commit('setStarRating', payload)
    },
    // cars actions
    setCarTimes (context, payload) {
      context.commit('setCarTimes', payload)
    },
    setCars (context, payload) {
      context.commit('setCars', payload)
    },
    setCarsTimeOut (context, payload) {
      context.commit('setCarsTimeOut', payload)
    },
    setCarsTimeOutFunction (context, payload) {
      context.commit('setCarsTimeOutFunction', payload)
    },
    setCarsSearchTimeOutFunction (context, payload) {
      context.commit('setCarsSearchTimeOutFunction', payload)
    },
    removeCarsResults (context) {
      context.commit('removeCarsResults')
    },
    clearDropLocation (context) {
      context.commit('clearDropLocation')
    },
    clearCarsTimeOutFunction (context) {
      context.commit('clearCarsTimeOutFunction')
    },
    clearCarsSearchTimeOutFunction (context) {
      context.commit('clearCarsSearchTimeOutFunction')
    },

    // agency
    setAgencyTrip (context, trip) {
      context.commit('setAgencyTrip', trip)
    },
    setAgency (context, agency) {
      context.commit('setAgency', agency)
    }
  },
  modules: {
  }
})
