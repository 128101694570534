<template>
  <v-card>
    <v-card-title class="d-flex justify-space-between align-center">
      <h2 class="primary--text">Your Friends</h2>
      <!-- <router-link to="/" class="primary--text">See all</router-link> -->
    </v-card-title>
    <v-row>
      <v-col cols="12" md="8">
        <div v-if="friends.length" class="d-flex flex-wrap px-5">
          <template v-for="item in friends">
            <div v-if="item.full_name"  class="text-center ma-4" :key="item.id" style="width: 200px;">
              <div style="cursor: pointer;" @click="$router.push({name: 'friendProfile', params: {id: item.id}})">
                <v-avatar v-if="item.profile_photo" size="62">
                    <img
                    :src="item.profile_photo"
                    :alt="item.full_name"
                    class="mb-3"
                    >
                </v-avatar>
                <v-avatar
                v-else
                color="primary"
                size="62"
                >
                  <span v-if="item.full_name" class="white--text text-capitalize">{{item.full_name.charAt(0)}}</span>
                  <span v-else class="white--text text-capitalize">{{item.user_name.charAt(0)}}</span>
                </v-avatar>
                <h3 class="paragraph--text bold-text text-capitalize my-1">{{item.full_name.split(' ')[0]}} {{item.full_name.split(' ')[1]}}</h3>
                <h3 v-if="!item.full_name" class="paragraph--text bold-text text-capitalize my-1" v-text="item.user_name"></h3>
                <p class="paragraph--text text-capitalize mb-1" v-text="item.nick_name"></p>
              </div>
              <v-progress-linear
              background-color="primary"
              color="primaryDark"
              :value="item.pivot.matching_percentage"
              class="mb-1 mx-auto"
              height="12"
              style="max-width: 100px; height: 20px;"
              rounded
              >
                <template v-slot:default>
                  <small class="white--text font-weight-bold body-2 mt-1">{{ item.pivot.matching_percentage }}%</small>
                </template>
              </v-progress-linear>
              <v-btn @click="$router.push({name: 'chat', params: {id: 1}})"
              class="d-block mx-auto rounded-lg" small elevation="0"
              color="primary white--text"
              >
                <img width="20" src="../../assets/caht.png"> <span class="ml-3">chat</span>
              </v-btn>
            </div>
          </template>
        </div>
        <div v-else class="text-center my-3 primary--text">You don't have friends yet! Make some</div>

        <div v-if="suggestedFriends.length">
          <v-divider class="my-3"></v-divider>
          <p class="primary--text text-h6 ml-3">Suggested Friends!</p>

          <div class="d-flex flex-wrap px-5">
            <template v-for="item in suggestedFriends">
              <div v-if="item.full_name" class="text-center ma-4" :key="item.id" style="width: 200px;">
                <div style="cursor: pointer;" @click="$router.push({name: 'friendProfile', params: {id: item.id}})">
                  <v-avatar v-if="item.profile_photo" size="62">
                      <img
                      :src="item.profile_photo"
                      :alt="item.full_name"
                      class="mb-3"
                      >
                  </v-avatar>
                  <v-avatar
                  v-else
                  color="primary"
                  size="62"
                  >
                    <span v-if="item.full_name" class="white--text text-capitalize">{{item.full_name.charAt(0)}}</span>
                    <span v-else class="white--text text-capitalize">{{item.user_name.charAt(0)}}</span>
                  </v-avatar>
                  <h3 class="paragraph--text bold-text text-capitalize my-1">{{item.full_name.split(' ')[0]}} {{item.full_name.split(' ')[1]}}</h3>
                  <p class="paragraph--text text-capitalize mb-1" v-text="item.nick_name"></p>
                  <v-progress-linear
                  background-color="primary"
                  color="primaryDark"
                  :value="item.pivot.matching_percentage"
                  class="mb-1 mx-auto"
                  height="12"
                  style="max-width: 100px; height: 20px;"
                  rounded
                  >
                    <template v-slot:default>
                      <small class="white--text font-weight-bold body-2 mt-1">{{ item.pivot.matching_percentage }}%</small>
                    </template>
                  </v-progress-linear>
                </div>
              </div>
            </template>
          </div>
        </div>
      </v-col>

      <v-col cols="12" md="4" class="d-flex">
        <v-divider vertical></v-divider>
        <div class="mx-2" style="width: 100%">
          <h3 class="primary--text">Search for Friends</h3>
          <v-autocomplete
          :items="suggestedFriends"
          color="primary"
          outlined
          placeholder="Search For A friend"
          class="mr-5 mt-2 rounded-lg"
          height="50"
          item-text="full_name"
          item-value="id"
          label="Search For A friend"
          full-width
          >
            <template v-slot:item="data">
              <!-- <template v-if="data.item.full_name"> -->
                <v-list-item-avatar @click="$router.push({name: 'friendProfile', params: {id: data.item.id}})">
                  <img v-if="data.item.profile_photo" :src="data.item.profile_photo">
                  <div v-else class="primary white--text text-capitalize pa-4 rounded-circle">
                    <span v-if="data.item.full_name">{{data.item.full_name.charAt(0)}}</span>
                    <span v-else>{{data.item.user_name.charAt(0)}}</span>
                  </div>
                </v-list-item-avatar>
                <v-list-item-content @click="$router.push({name: 'friendProfile', params: {id: data.item.id}})">
                  <v-list-item-title v-if="data.item.full_name">{{data.item.full_name.split(' ')[0]}} {{data.item.full_name.split(' ')[1]}}</v-list-item-title>
                  <v-list-item-subtitle v-if="data.item.user_name" v-html="data.item.user_name"></v-list-item-subtitle>
                  <v-progress-linear
                  background-color="primary"
                  color="primaryDark"
                  :value="data.item.pivot.matching_percentage"
                  class="mb-1"
                  height="12"
                  style="max-width: 100px; height: 20px;"
                  rounded
                  >
                    <template v-slot:default>
                      <small class="white--text font-weight-bold body-2 mt-1">{{ data.item.pivot.matching_percentage }}%</small>
                    </template>
                  </v-progress-linear>
                </v-list-item-content>
              <!-- </template> -->
            </template>
          </v-autocomplete>
          <!-- <v-autocomplete
          :items="suggestedFriends"
          full-width
          append-icon="mdi-magnify"
          :filter="customFilter"
          outlined
          placeholder="Search For A friend"
          class="mr-5 mt-2 rounded-lg"
          height="50"
          item-text="name"
          item-value="name"
          >
            <template v-slot:item="data">
              <template v-if="data.item.full_name">
                <v-list-item-avatar @click="$router.push({name: 'friendProfile', params: {id: data.item.id}})">
                  <img v-if="data.item.profile_photo" :src="data.item.profile_photo">
                  <div v-else class="primary white--text text-capitalize pa-4 rounded-circle">
                    <span v-if="data.item.full_name">{{data.item.full_name.charAt(0)}}</span>
                    <span v-else>{{data.item.user_name.charAt(0)}}</span>
                  </div>
                </v-list-item-avatar>
                <v-list-item-content @click="$router.push({name: 'friendProfile', params: {id: data.item.id}})">
                  <v-list-item-title v-if="data.item.full_name">{{data.item.full_name.split(' ')[0]}} {{data.item.full_name.split(' ')[1]}}</v-list-item-title>
                  <v-list-item-subtitle v-if="data.item.user_name" v-html="data.item.user_name"></v-list-item-subtitle>
                  <v-progress-linear
                  background-color="primary"
                  color="primaryDark"
                  :value="item.pivot.matching_percentage"
                  class="mb-1 mx-auto"
                  height="12"
                  style="max-width: 100px; height: 20px;"
                  rounded
                  >
                    <template v-slot:default>
                      <small class="white--text font-weight-bold body-2 mt-1">{{ item.pivot.matching_percentage }}%</small>
                    </template>
                  </v-progress-linear>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete> -->
          <h3 class="primary--text">Friends Requests</h3>
          <div style="cursor: pointer;"
          class="d-felx ma-4"
          v-for="(item) in friendsRequests" :key="item.id"
          >
            <div @click="$router.push({name: 'friendProfile', params: {id: item.id}})">
              <v-avatar v-if="item.profile_photo" size="30">
                  <img
                  :src="item.profile_photo"
                  :alt="item.full_name"
                  class="mb-3"
                  >
              </v-avatar>
              <v-avatar
              v-else
              color="primary"
              size="30"
              class="mb-2"
              >
                <span class="white--text">{{item.full_name ? item.full_name.charAt(0).toUpperCase() : item.user_name.charAt(0).toUpperCase()}}</span>
              </v-avatar>
              <span @click="$router.push({name: 'friendProfile', params: {id: item.id}})" class="paragraph--text bold-text body-1 ml-3 text-uppercase">{{item.full_name.split(' ')[0]}} {{item.full_name.split(' ')[1]}}</span>
              <v-progress-linear
              background-color="primary"
              color="primaryDark"
              :value="item.matching_percentage"
              class="mb-1 ml-10"
              height="12"
              style="max-width: 100px; height: 20px;"
              rounded
              >
                <template v-slot:default>
                  <small class="white--text font-weight-bold body-2 mt-1">{{ item.matching_percentage }}%</small>
                </template>
              </v-progress-linear>
            </div>
            <div class="ml-5">
              <v-btn @click="confirmRequest(item.id)" text color="success">Confirm</v-btn>
              <v-btn @click="cancelFriend(item.id)" text color="error">iGNORE</v-btn>
            </div>
          </div>
          <div v-if="!friendsRequests.length">
            <v-img width="50%" class="mx-auto ml-15 mt-10" src="../../assets/no-friends.png"></v-img>
            <p class="text-center primary--text">No friend requests to show!</p>
          </div>

          <h3 class="primary--text">Sent Requests</h3>
          <div style="cursor: pointer;"
          class="d-felx ma-4"
          v-for="(item) in pendingFriends" :key="item.id"
          >
            <div @click="$router.push({name: 'friendProfile', params: {id: item.id}})">
              <v-avatar v-if="item.profile_photo" size="30">
                  <img
                  :src="item.profile_photo"
                  :alt="item.full_name"
                  class="mb-3"
                  >
              </v-avatar>
              <v-avatar
              v-else
              color="primary"
              size="30"
              class="mb-2"
              >
                <span class="white--text">{{item.full_name.charAt(0).toUpperCase()}}</span>
              </v-avatar>
              <span @click="$router.push({name: 'friendProfile', params: {id: item.id}})" class="paragraph--text bold-text body-1 ml-3 text-uppercase">{{item.full_name.split(' ')[0]}} {{item.full_name.split(' ')[1]}}</span>
              <v-progress-linear
              background-color="primary"
              color="primaryDark"
              :value="item.pivot.matching_percentage"
              class="mb-1 ml-10"
              height="12"
              style="max-width: 100px; height: 20px;"
              rounded
              >
                <template v-slot:default>
                  <small class="white--text font-weight-bold body-2 mt-1">{{ item.pivot.matching_percentage }}%</small>
                </template>
              </v-progress-linear>
            </div>
          </div>
          <div v-if="!pendingFriends.length">
            <v-img width="50%" class="mx-auto ml-15 mt-10" src="../../assets/no-friends.png"></v-img>
            <p class="text-center primary--text">No friend requests to show!</p>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import user from '@/web_services/user'

export default {
  data () {
    return {
      friends: [],
      suggestedFriends: [],
      friendsRequests: [],
      pendingFriends: [],
      loading: true,
      select: null
    }
  },
  methods: {
    confirmRequest (id) {
      user.confirmFriend(id).then((res) => {
        this.friendsRequests.splice(this.friendsRequests.findIndex(item => item.id === id), 1)
        this.getFriends()
      }).catch(() => {
      }).finally(() => {
        this.loading = false
      })
    },
    cancelFriend (id) {
      user.cancelFriend(id).then((res) => {
        this.friendsRequests.splice(this.friendsRequests.findIndex(item => item.id === id), 1)
      }).catch(() => {
      }).finally(() => {
        this.loading = false
      })
    },
    customFilter (item, queryText, itemText) {
      const textOne = item.full_name ? item.full_name.toLowerCase() : ''
      const textTwo = item.user_name ? item.user_name.toLowerCase() : ''
      const searchText = queryText.toLowerCase()

      return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
    },
    getFriends () {
      user.getFriends().then((res) => {
        console.log('friends', res.data.data)
        this.friends = res.data.data
        this.loading = false
      }).catch(() => {
      }).finally(() => {
        this.loading = false
      })
    }
  },
  created () {
    this.getFriends()

    user.suggestedFriends().then(res => {
      console.log('suggested', res.data.data)
      this.suggestedFriends = res.data.data
    }).catch(() => {
      this.suggestedFriends = []
    }).finally(() => {
      this.loading = false
    })

    user.pendingFriends().then(res => {
      console.log('pending', res.data.data)
      this.pendingFriends = res.data.data
    }).catch(() => {
      this.pendingFriends = []
    }).finally(() => {
      this.loading = false
    })

    user.friendsRequests().then(res => {
      console.log('requests', res.data.data)
      this.friendsRequests = res.data.data
    }).catch(() => {
      this.friendsRequests = []
    }).finally(() => {
      this.loading = false
    })
  }
}
</script>
