<template>
  <div>
    <v-snackbar
    color="error"
    top
    v-model="snackbar"
    :timeout="5000"
    id="snackbar"
    >
      <v-row>
      {{text}}
      <v-spacer></v-spacer>
      <v-btn
        dark
        icon
        @click="snackbar = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      </v-row>
    </v-snackbar>
    <!-- radio group  -->
    <v-form v-model="valid" ref="flightForm">
      <v-row no-gutters class="mb-5">
        <!-- <v-col> -->
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :color="$route.name === 'flights' ? 'primary' : 'white'"
                dark
                text
                v-bind="attrs"
                v-on="on"
              >
                {{flightType === 'one' ? 'One Way Trip' : (flightType === 'round' ? 'Round Trip' : 'Multi City')}}
                <v-icon right>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="flightType = 'round';">
                <v-list-item-title>Round Trip</v-list-item-title>
              </v-list-item>
              <v-list-item @click="flightType = 'one';">
                <v-list-item-title>One Way</v-list-item-title>
              </v-list-item>
              <v-list-item @click="flightType = 'multi';">
                <v-list-item-title>Multi city</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        <!-- </v-col> -->
        <!-- <v-col class="text-sm-center"> -->
          <passengers/>
        <!-- </v-col> -->
        <!-- <v-col class="text-sm-right"> -->
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
              :color="$route.name === 'flights' ? 'primary' : 'white'"
              dark
              text
              v-bind="attrs"
              v-on="on"
              >
                {{$store.state.passengers.classType.text}}
                <v-icon right>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in classes"
                :key="index"
                @click="setClass(item);"
              >
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        <!-- </v-col> -->
      </v-row>

      <!-- inputs section -->
      <v-row justify="space-between">
        <v-col cols="12" md="3">
          <cities @error="snackbar = true; text='Something went wrong'" type="Departure"></cities>
        </v-col>
        <!-- <v-col cols="12" md="1"> -->
          <v-btn @click="swapAirports(1)" large class="mt-5 d-none d-md-block" icon :color="$route.name === 'flights' ? 'primary' : 'white'"><v-icon>mdi-swap-horizontal</v-icon></v-btn>
        <!-- </v-col> -->
        <v-col cols="12" md="3">
          <cities @error="snackbar = true; text='Something went wrong'" type="Arrival" @setSecondDeparture="setSecondDeparture"></cities>
        </v-col>
        <v-col cols="12" md="3">
          <picker key="return" v-if="flightType === 'round'" type="Return Date"></picker>
          <picker key="depart1" v-if="flightType === 'one' || flightType === 'multi'" type="Departure Date"></picker>
        </v-col>
        <v-col cols="12" md="2" class="text-right">
          <v-btn
          block
          class="rounded-lg d-none d-md-block"
          :class="$route.name === 'flights' ? 'white--text' : 'primary--text'"
          :color="$route.name === 'flights' ? 'primary' : 'white'"
          height="60"
          @click="submit"
          :loading="loading"
          id="flights-search-submit"
          elevation="0"
          >
          search
          </v-btn>
        </v-col>
      </v-row>

        <!-- second autocomplete components for multi -->
      <v-row justify="space-between" v-if="flightType === 'multi'">
        <v-col cols="12" md="3">
          <cities @error="snackbar = true; text='Something went wrong'" type="Second Departure" :getSecondDeparture="secondDeparture"></cities>
        </v-col>
        <v-btn @click="swapAirports(2)" large class="mt-5 d-none d-md-block" icon :color="$route.name === 'flights' ? 'primary' : 'white'"><v-icon>mdi-swap-horizontal</v-icon></v-btn>
        <v-col cols="12" md="3">
          <cities @error="snackbar = true; text='Something went wrong'" type="Second Arrival" @setThirdDeparture="setThirdDeparture"></cities>
        </v-col>
        <v-col cols="12" md="3">
          <picker key="depart2" type="Second Departure Date"></picker>
        </v-col>
        <v-col cols="12" md="2">
          <v-btn text x-large color="success" v-if="flightType === 'multi' && !segments" @click="segments = !segments">
            <v-icon left>mdi-plus-circle</v-icon>
            Add
          </v-btn>
        </v-col>
      </v-row>

        <!-- third autocomplete components for multi -->
      <v-row justify="space-between" v-if="flightType === 'multi' && segments">
        <v-col cols="12" md="3">
          <cities @error="snackbar = true; text='Something went wrong'" type="Third Departure" :getThirdDeparture="thirdDeparture"></cities>
        </v-col>
        <v-btn @click="swapAirports(3)" large class="mt-5 d-none d-md-block" icon :color="$route.name === 'flights' ? 'primary' : 'white'"><v-icon>mdi-swap-horizontal</v-icon></v-btn>
        <v-col cols="12" md="3">
          <cities @error="snackbar = true; text='Something went wrong'" type="Third Arrival"></cities>
        </v-col>
        <v-col cols="12" md="3">
          <picker key="depart3" type="Third Departure Date"></picker>
        </v-col>
        <v-col cols="12" md="2">
          <v-btn x-large text color="error" v-if="flightType === 'multi' && segments" @click="removeSegment">
            <v-icon left>mdi-close-circle</v-icon>
            Remove
          </v-btn>
        </v-col>
      </v-row>
      <v-btn
      class="rounded-lg d-block d-md-none"
      :class="$route.name === 'flights' ? 'white--text' : 'primary--text'"
      :color="$route.name === 'flights' ? 'primary' : 'white'"
      block
      height="60"
      @click="submit"
      :loading="loading"
      id="flights-search-submit"
      elevation="0"
      >
      search
      </v-btn>
    </v-form>
  </div>
</template>

<script>
import cities from '@/components/flights/airportsAutocomplete'
import picker from '@/components/flights/datePicker.vue'
import passengers from '@/components/flights/passengers'

export default {
  components: {
    cities,
    picker,
    passengers
  },
  props: ['loading'],
  data () {
    return {
      flightType: 'round',
      today: new Date().toISOString().substr(0, 10),
      size: '6',
      valid: true,
      secondDeparture: null,
      thirdDeparture: null,
      segments: true,
      snackbar: false,
      text: '',
      color: '',
      classType: { text: 'Economy', value: 'Y' },
      classes: [
        { text: 'Economy', value: 'Y' },
        { text: 'Premium', value: 'S' },
        { text: 'Business', value: 'C' },
        { text: 'First', value: 'F' }
      ],
      directFlights: false
    }
  },
  watch: {
    flightType (val) {
      if (val !== 'round') {
        this.size = '11'
      } else {
        this.size = '6'
      }
    }
  },
  computed: {
    tomorrow () {
      const tomorrow = new Date(this.today)
      tomorrow.setDate(tomorrow.getDate() + 1)
      return tomorrow.toISOString().slice(0, 10)
    }
  },
  methods: {
    setClass (flightClass) {
      this.$store.dispatch('setPassengersClass', flightClass)
    },
    setSecondDeparture (value) {
      this.secondDeparture = value
    },
    setThirdDeparture (value) {
      this.thirdDeparture = value
    },
    swapAirports (segment) {
      const departureAirports = [...this.$store.state.airports.departureAirports]
      const arrivalAirports = [...this.$store.state.airports.arrivalAirports]
      if (segment === 1 && departureAirports[0] && arrivalAirports[0]) {
        this.$store.dispatch('setAirports', { data: arrivalAirports[0], type: 'departure1' })
        this.$store.dispatch('setAirports', { data: departureAirports[0], type: 'arrival1' })
        document.getElementById('ac-selection-Departure').innerText = arrivalAirports[0].Name + ',' + arrivalAirports[0].Code
        document.getElementById('ac-selection-Arrival').innerText = departureAirports[0].Name + ',' + departureAirports[0].Code
      } else if (segment === 2 && departureAirports[1] && arrivalAirports[1]) {
        this.$store.dispatch('setAirports', { data: arrivalAirports[1], type: 'departure2' })
        this.$store.dispatch('setAirports', { data: departureAirports[1], type: 'arrival2' })
        document.getElementById('ac-selection-Second Departure').innerText = arrivalAirports[1].Name + ',' + arrivalAirports[1].Code
        document.getElementById('ac-selection-Second Arrival').innerText = departureAirports[1].Name + ',' + departureAirports[1].Code
      } else if (segment === 3 && departureAirports[2] && arrivalAirports[2]) {
        this.$store.dispatch('setAirports', { data: arrivalAirports[2], type: 'departure3' })
        this.$store.dispatch('setAirports', { data: departureAirports[2], type: 'arrival3' })
        document.getElementById('ac-selection-Third Departure').innerText = arrivalAirports[2].Name + ',' + arrivalAirports[2].Code
        document.getElementById('ac-selection-Third Arrival').innerText = departureAirports[2].Name + ',' + departureAirports[2].Code
      }
      // document.getElementById('ac-selection-Departure').innerText
    },
    removeSegment () {
      this.segments = !this.segments
      this.$store.dispatch('removeSegment')
      this.$refs.flightForm.resetValidation()
    },
    submit () {
      this.$refs.flightForm.validate()
      const searchStore = this.$store.state
      switch (this.flightType) {
        case 'multi':
          if (
            (
              this.flightType === 'multi' &&
              this.segments &&
              searchStore.airports.departureAirports[0] &&
              searchStore.airports.departureAirports[1] &&
              searchStore.airports.departureAirports[2] &&
              searchStore.airports.arrivalAirports[0] &&
              searchStore.airports.arrivalAirports[1] &&
              searchStore.airports.arrivalAirports[2] &&
              searchStore.flightDates.departureDates[0] &&
              searchStore.flightDates.departureDates[1] &&
              searchStore.flightDates.departureDates[2]
            ) ||
            (
              this.flightType === 'multi' &&
              !this.segments &&
              searchStore.airports.departureAirports[0] &&
              searchStore.airports.departureAirports[1] &&
              searchStore.airports.arrivalAirports[0] &&
              searchStore.airports.arrivalAirports[1] &&
              searchStore.flightDates.departureDates[0] &&
              searchStore.flightDates.departureDates[1]
            )
          ) this.valid = true
          break
        case 'round':
          if (
            searchStore.airports.departureAirports[0] &&
            searchStore.airports.arrivalAirports[0] &&
            searchStore.flightDates.departureDates[0] &&
            searchStore.flightDates.arrivalDate
          ) this.valid = true
          break
        case 'one':
          if (searchStore.airports.departureAirports[0] &&
            searchStore.airports.arrivalAirports[0] &&
            searchStore.flightDates.departureDates[0]
          ) this.valid = true
          break
      }
      if (this.valid) {
        const searchParameters = {
          tripType: this.flightType
        }
        this.$store.dispatch('setType', this.flightType)
        let data = ''
        let string = ''
        if (!this.isSimilarAirpots()) {
          if (new Date(searchStore.flightDates.departureDates[0]).getTime() > new Date().getTime() || (searchStore.mode === 'ats' || searchStore.mode === 'atsDev')) {
            if (new Date(searchStore.flightDates.departureDates[0]).getTime() <= new Date(searchStore.flightDates.arrivalDate).getTime() || this.flightType !== 'round') {
              switch (this.flightType) {
                case 'round':
                  // if (searchStore.airports.departureAirports[0] &&
                  //     searchStore.airports.arrivalAirports[0] &&
                  //     searchStore.flightDates.departureDates[0] &&
                  //     searchStore.flightDates.arrivalDate
                  // ) {
                  searchParameters.origin = searchStore.airports.departureAirports[0].Code
                  searchParameters.destination = searchStore.airports.arrivalAirports[0].Code
                  searchParameters.originType = searchStore.airports.departureAirports[0].Type
                  searchParameters.destinationType = searchStore.airports.arrivalAirports[0].Type
                  searchParameters.departureDate = searchStore.flightDates.departureDates[0]
                  searchParameters.arrivalDate = searchStore.flightDates.arrivalDate
                  searchParameters.classType = searchStore.passengers.classType.value
                  searchParameters.adults = searchStore.passengers.adults
                  searchParameters.children = searchStore.passengers.children
                  searchParameters.infants = searchStore.passengers.infants
                  if (searchStore.preferredAirline) searchParameters.preferredAirline = searchStore.preferredAirline.code
                  searchParameters.directFlights = this.directFlights ? 1 : 0
                  data = Object.entries(searchParameters).map(
                    ([key, val]) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`
                  ).join('&')
                  this.request(data)
                  // } else {
                  //   this.snackbar = true
                  //   this.color = 'error'
                  //   this.text = 'Please fill all fields'
                  // }
                  break
                case 'one':
                  searchParameters.origin = searchStore.airports.departureAirports[0].Code
                  searchParameters.destination = searchStore.airports.arrivalAirports[0].Code
                  searchParameters.originType = searchStore.airports.departureAirports[0].Type
                  searchParameters.destinationType = searchStore.airports.arrivalAirports[0].Type
                  searchParameters.departureDate = searchStore.flightDates.departureDates[0]
                  searchParameters.classType = searchStore.passengers.classType.value
                  searchParameters.adults = searchStore.passengers.adults
                  searchParameters.children = searchStore.passengers.children
                  searchParameters.infants = searchStore.passengers.infants
                  if (searchStore.preferredAirline) searchParameters.preferredAirline = searchStore.preferredAirline.code
                  searchParameters.directFlights = this.directFlights ? 1 : 0
                  data = Object.entries(searchParameters).map(
                    ([key, val]) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`
                  ).join('&')
                  this.request(data)
                  break
                case 'multi':
                  searchParameters.origin = searchStore.airports.departureAirports
                  searchParameters.destination = searchStore.airports.arrivalAirports
                  searchParameters.departureDate = searchStore.flightDates.departureDates
                  searchParameters.classType = searchStore.passengers.classType.value
                  searchParameters.adults = searchStore.passengers.adults
                  searchParameters.children = searchStore.passengers.children
                  searchParameters.infants = searchStore.passengers.infants
                  string = 'tripType=multi'
                  for (let i = 0; i < searchParameters.origin.length; i++) {
                    if (i === 2 && searchParameters.origin[2] === null) {
                      continue
                    }
                    string += `&origin[]=${searchParameters.origin[i].Code}&originType[]=${searchParameters.origin[i].Type}&destination[]=${searchParameters.destination[i].Code}&destinationType[]=${searchParameters.destination[i].Type}&departureDate[]=${searchParameters.departureDate[i]}`
                  }
                  string += `&classType=${searchParameters.classType}&adults=${searchParameters.adults}&children=${searchParameters.children}&infants=${searchParameters.infants}`
                  string += `&directFlights=${this.directFlights ? 1 : 0}`
                  if (searchStore.preferredAirline) string += `&preferredAirline=${searchStore.preferredAirline.code}`
                  this.request(string)
                  break
                default:
                  break
              }
            } else {
              this.snackbar = true
              this.color = 'error'
              this.text = 'Please choose departure date before the arrival date you chose'
            }
          } else {
            this.snackbar = true
            this.color = 'error'
            this.text = 'Please choose departure date starting after today'
          }
        } else {
          this.snackbar = true
          this.color = 'error'
          this.text = 'Choose destinations different from origins'
        }
      } else {
        this.snackbar = true
        this.color = 'error'
        this.text = 'Please fill all fields'
      }
    },
    isSimilarAirpots () {
      const origins = this.$store.state.airports.departureAirports.filter(item => item !== null)
      const destinations = this.$store.state.airports.arrivalAirports.filter(item => item !== null)
      let isSimilar = false
      if (this.flightType === 'one' || this.flightType === 'round') {
        if (origins[0].Code === destinations[0].Code) isSimilar = true
      } else {
        for (let index = 0; index < origins.length; index++) {
          if (origins[index].Code === destinations[index].Code) isSimilar = true
        }
      }
      return isSimilar
    },
    request (data) {
      this.$store.dispatch('setFlightsTimeOut', false)
      // this.$store.dispatch('setDirectFlights', this.directFlights)
      if (this.$store.state.flightsSearchTimeOutFunction !== null) this.$store.dispatch('clearFlightsSearchTimeoutFunction')
      if (this.$store.state.flightsTimeOutFunction !== null) this.$store.dispatch('clearFlightsTimeoutFunction')
      this.$store.dispatch('removeFlightResults')
      let path
      const searchStore = this.$store.state
      switch (this.flightType) {
        case 'one':
          path = `from=${searchStore.airports.departureAirports[0].Name}-to=${searchStore.airports.arrivalAirports[0].Name}`
          break
        case 'round':
          path = `from=${searchStore.airports.departureAirports[0].Name}-to=${searchStore.airports.arrivalAirports[0].Name}`
          break
        case 'multi':
          for (let i = 0; i < searchStore.airports.departureAirports.length; i++) {
            switch (i) {
              case 0:
                path = `from1=${searchStore.airports.departureAirports[0].Name}-to1=${searchStore.airports.arrivalAirports[0].Name}`
                break
              case 1:
                path += `&from2=${searchStore.airports.departureAirports[1].Name}-to2=${searchStore.airports.arrivalAirports[1].Name}`
                break
              case 2:
                if (searchStore.airports.departureAirports[2]) {
                  path += `&from3=${searchStore.airports.departureAirports[2].Name}-to3=${searchStore.airports.arrivalAirports[2].Name}`
                }
                break
            }
          }
          break
      }
      if (this.$route.name === 'flightResults') {
        this.$emit('addFlights', data)
        this.$route.params.query = path
      } else this.$router.push({ name: 'flightResults', params: { term: data, query: path } })
    }
  },
  created () {
    if (this.$store.state.flightType === '') this.flightType = 'round'
    else this.flightType = this.$store.state.flightType
    if (this.$store.state.isDirectFlights) this.directFlights = this.$store.state.isDirectFlights
    if (this.$route.name === 'flightResults' && !this.$store.state.airports.arrivalAirports[2]) this.segments = false
    this.classType = this.$store.state.passengers.classType
  }
}
</script>

<style>
</style>
