<template>
  <div>
    <div v-if="user">
      <v-container v-if="user.role !== 'Admin' && user.role !== 'Agency'" fluid class="pa-10 elevation-1">
        <v-row justify="space-between" align="center">
          <!-- logo -->
          <img @click="$router.push({ path: '/' })" class="d-none d-md-block logo-img" :src="require('@/assets/logo.png')"
              alt="Group Trip">
          <img @click="$router.push({ path: '/' })" class="d-block d-md-none logo-img"
              :src="require('@/assets/logo-sm.png')" alt="Group Trip">

          <v-btn @click="$router.push({ path: '/quickPlanner' })" elevation="0" color="primary" class="py-0 px-7 font-weight-regular ml-3 rounded-lg">create trip
          </v-btn>

          <!-- actions small screens -->
          <div class="d-block d-md-none">
            <v-menu
              v-if="user"
              bottom
              min-width="200px"
              rounded
              offset-y
              transition="slide"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  large
                  v-on="on"
                >
                  <v-avatar
                    color="primary"
                    size="32"
                    class="mr-3"
                  >
                    <img
                      v-if="user.profile_photo"
                      :src="user.profile_photo"
                    >
                    <span v-else class="white--text text-h5">{{ user.full_name.split(' ')[0].charAt(0) }}</span>
                  </v-avatar>
                </v-btn>
              </template>
              <v-card>
                <v-list dense>
                  <v-list-item-group
                    v-model="selectedItem"
                    color="primary"
                  >
                    <v-list-item v-for="item in accountLinks" :key="item.label" :to="item.route">
                      <v-list-item-icon>
                        <v-icon v-text="item.icon"></v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title v-text="item.label"></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <!-- log out -->
                    <v-list-item @click="logout">
                      <v-list-item-icon>
                        <v-icon>mdi-logout</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Logout</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-menu>
            <v-btn x-large color="primaryDark" @click="showSideNav = !showSideNav" icon>
              <v-icon>mdi-menu</v-icon>
            </v-btn>
          </div>

          <!-- actions large screens -->
          <div class="d-none d-md-block">
            <!-- currency -->
            <v-menu
              bottom
              center
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primaryDark"
                  text
                  v-bind="attrs"
                  v-on="on"
                  large
                  class="px-0 mr-3"
                >
                  <v-icon>mdi-cash</v-icon>
                  USD
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item>
                  <v-list-item-title>EGP</v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>USD</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <div class="vertical-line"></div>

            <v-menu
              bottom
              center
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primaryDark"
                  text
                  v-bind="attrs"
                  v-on="on"
                  large
                  class="px-0 mr-3"
                >
                  <img src="../../assets/images/usaflag.png" alt="">
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item>
                  <v-list-item-title>
                    <img class="mt-2" src="../../assets/images/usaflag.png" alt="">
                    <span class="ml-2">English</span>
                  </v-list-item-title>
                </v-list-item>
                <!-- <v-list-item>
                    <v-list-item-title>
                        <img src="../../assets/images/flag.png" alt="">
                    </v-list-item-title>
                </v-list-item> -->
              </v-list>
            </v-menu>
            <div class="vertical-line"></div>

            <v-btn
              text
              large
              @click="$router.push('/profile/about')"
              class="text-bold"
            >
              <v-avatar
                color="primary"
                size="32"
                class="mr-3"
              >
                <img
                  v-if="user.profile_photo"
                  :src="user.profile_photo"
                >
                <span v-else class="white--text text-h5">{{ user.full_name.split(' ')[0].charAt(0) }}</span>
              </v-avatar>
              <span v-if="user.full_name">{{ user.full_name.split(' ')[0] }}</span>
            </v-btn>

            <!-- user options -->
    <!--        <v-menu-->
    <!--          v-if="user"-->
    <!--          bottom-->
    <!--          min-width="200px"-->
    <!--          rounded-->
    <!--          offset-y-->
    <!--          transition="slide"-->
    <!--        >-->
    <!--          <template v-slot:activator="{ on }">-->
    <!--            <v-btn-->
    <!--              text-->
    <!--              large-->
    <!--              v-on="on"-->
    <!--              class="text-bold"-->
    <!--            >-->
    <!--              <v-avatar-->
    <!--                color="primary"-->
    <!--                size="32"-->
    <!--                class="mr-3"-->
    <!--              >-->
    <!--                <img-->
    <!--                  v-if="user.profile_photo"-->
    <!--                  :src="user.profile_photo"-->
    <!--                >-->
    <!--                <span v-else class="white&#45;&#45;text text-h5">{{ user.full_name.split(' ')[0].charAt(0) }}</span>-->
    <!--              </v-avatar>-->
    <!--              <span v-if="user.full_name">{{ user.full_name.split(' ')[0] }}</span>-->
    <!--            </v-btn>-->
    <!--          </template>-->
    <!--          <v-card>-->
    <!--            <v-list dense>-->
    <!--              <v-list-item-group-->
    <!--                v-model="selectedItem"-->
    <!--                color="primary"-->
    <!--              >-->
    <!--                <v-list-item v-for="item in accountLinks" :key="item.label" :to="item.route">-->
    <!--                  <v-list-item-icon>-->
    <!--                    <v-icon v-text="item.icon"></v-icon>-->
    <!--                  </v-list-item-icon>-->
    <!--                  <v-list-item-content>-->
    <!--                    <v-list-item-title v-text="item.label"></v-list-item-title>-->
    <!--                  </v-list-item-content>-->
    <!--                </v-list-item>-->
    <!--                <v-list-item v-if="user.role === 'Admin'" @click="admin()">-->
    <!--                  <v-list-item-icon>-->
    <!--                    <v-icon>mdi-account-check</v-icon>-->
    <!--                  </v-list-item-icon>-->
    <!--                  <v-list-item-content>-->
    <!--                    <v-list-item-title>Admin</v-list-item-title>-->
    <!--                  </v-list-item-content>-->
    <!--                </v-list-item>-->
    <!--              </v-list-item-group>-->
    <!--            </v-list>-->
    <!--          </v-card>-->
    <!--        </v-menu>-->

            <!-- register -->
            <!-- <v-btn outlined color="primaryDark" class="py-0 px-7 font-weight-regular mx-3 rounded-lg">register</v-btn> -->
            <div class="vertical-line"></div>
            <!-- log out -->
            <v-btn color="primary" text class="ml-2" @click="logout"><v-icon>mdi-logout</v-icon> Logout</v-btn>
          </div>
        </v-row>

        <!-- navigation drawer for small devices -->
        <v-navigation-drawer
          v-model="showSideNav"
          absolute
          top
          temporary
          class="pa-5"
        >
          <img class="d-block logo-img" :src="require('@/assets/logo.png')" alt="Group Trip">
          <v-divider class="my-5"></v-divider>

          <!-- rigester -->
          <div class="d-block">
            <!-- <v-btn outlined color="primaryDark" class="py-0 px-7 my-5 font-weight-regular rounded-lg">register</v-btn> -->
            <v-btn @click="$router.push({ path: '/quickPlanner' })" elevation="0" color="primary" class="py-0 px-7 my-5 font-weight-regular rounded-lg">Create trip</v-btn>
          </div>

          <v-menu
            bottom
            center
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primaryDark"
                text
                v-bind="attrs"
                v-on="on"
                x-large
                class="my-5 px-0"
              >
                <v-icon>mdi-cash</v-icon>
                Egp
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item>
                <v-list-item-title>EGP</v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>USD</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-menu
              bottom
              center
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primaryDark"
                  text
                  v-bind="attrs"
                  v-on="on"
                  large
                  class="px-0 mr-3"
                >
                  <img src="../../assets/images/flag.png" alt="">
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item>
                  <v-list-item-title>
                    <img src="../../assets/images/flag.png" alt="">
                    <span class="ml-2">English</span>
                  </v-list-item-title>
                </v-list-item>
                <!-- <v-list-item>
                    <v-list-item-title>
                        <img src="../../assets/images/flag.png" alt="">
                    </v-list-item-title>
                </v-list-item> -->
              </v-list>
            </v-menu>
        </v-navigation-drawer>

        <v-divider class="my-5"></v-divider>

        <!-- booking forms control -->
        <div class="d-flex justify-space-between">
        <v-btn
          :text="currentForm !== item.label || $route.name === 'Home'"
          :outlined="currentForm === item.label && $route.name !== 'Home'"
          :color="currentForm === item.label && $route.name !== 'Home' ? 'primaryDark' : 'paragraph'"
          class=""
          v-for="item in routes"
          :key="item.label"
          @click="setForm(item.label)"
        >
          <v-icon>{{ item.icon }}</v-icon>
          <span>{{ item.label === 'plansList' ? 'trips' : item.label }}</span>
        </v-btn>
        <v-spacer></v-spacer>
        <!-- <v-btn
        x-large
        :text="$route.name === 'Home'"
        :outlined="$route.path.includes('quickPlanner')"
        :color="$route.path.includes('quickPlanner') ? 'primaryDark' : 'primary'"
        @click="$router.push('/quickPlanner')"
        >
          <v-icon></v-icon>
          <span class="font-weight-bold">quick planner</span>
        </v-btn> -->
      </div>
        <!-- <v-btn v-if="user.role === 'Admin'" absolute right text @click="admin()"><v-icon>mdi-account-check</v-icon> Admin</v-btn> -->
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import auth from '@/web_services/auth'

export default {
  data () {
    return {
      selectedItem: 1,
      showSideNav: false,
      accountLinks: [
        {
          label: 'My Account',
          icon: 'mdi-account',
          route: '/profile/about'
        },
        {
          label: 'Bookings',
          icon: 'mdi-airplane',
          route: '/profile/bookings'
        },
        {
          label: 'Favourites',
          icon: 'mdi-heart',
          route: '/profile/favourites'
        }
      ],
      routes: [
        {
          label: 'plansList',
          icon: 'mdi-beach'
        },
        {
          label: 'flights',
          icon: 'mdi-airplane'
        },
        {
          label: 'stays',
          icon: 'mdi-bed'
        },
        {
          label: 'cars',
          icon: 'mdi-car'
        }
      ]
    }
  },
  computed: {
    // list desired states from vuex store
    ...mapState(['currentForm', 'user'])
  },
  methods: {
    setForm (form) {
      this.$store.dispatch('setCurrentForm', form)
      if (this.$route.name !== form) this.$router.push({ name: form })
    },
    removeUserData () {
      this.$store.dispatch('removeUser')
      this.$cookies.remove('gt_user_token')
      this.$router.push({ path: '/auth' })
    },
    logout () {
      auth.logout().then(res => {
        this.removeUserData()
      }).catch(err => {
        if (err.response.status === 401) this.removeUserData()
      })
    },
    admin () {
      this.$router.push('/admin/interests')
    }
  }
}
</script>

<style scoped>
.vertical-line {
  border-right: 0.5px solid #FF1572;
  display: inline;
}

/* .router-link-exact-active {
    border: 1px solid #FF1572;
    border-radius: 10px;
    padding: 7px 20px;
}
.router-link-exact-active span {
    color: #FF1572;
}
.router-link-exact-active .v-icon::before {
    color: #FF1572;
} */
</style>
