import Vue from 'vue'
import { baseUrl } from '../endpoints'

const timeout = 20000

export default {
  // register
  profile () {
    return Vue.axios.get(baseUrl + 'user/profile',
      {
        timeout: timeout,
        //   headers: { Accept: 'application/json', 'Content-type': 'application/json' },
        data: null
      }
    )
  },
  update (data) {
    return Vue.axios.post(baseUrl + 'user/update', data, {
      timeout: timeout
    }
    )
  },
  delete () {
    return Vue.axios.delete(baseUrl + 'user/delete', {
      timeout: timeout
    }
    )
  },
  wishlist () {
    return Vue.axios.get(baseUrl + 'user/wishlists',
      {
        timeout: timeout,
        //   headers: { Accept: 'application/json', 'Content-type': 'application/json' },
        data: null
      }
    )
  },
  maritalStatus () {
    return Vue.axios.get(baseUrl + 'user/marital_statuses',
      {
        timeout: timeout,
        //   headers: { Accept: 'application/json', 'Content-type': 'application/json' },
        data: null
      }
    )
  },
  bookings () {
    return Vue.axios.get(baseUrl + 'user/flightbookings',
      {
        timeout: timeout,
        //   headers: { Accept: 'application/json', 'Content-type': 'application/json' },
        data: null
      }
    )
  },
  addwishlist (data) {
    return Vue.axios.post(baseUrl + 'user/wishlist/add', data, {
      timeout: timeout
    })
  },
  updatewishlist (data, id) {
    return Vue.axios.post(baseUrl + `user/wishlist/${id}/update`, data, {
      timeout: timeout
    })
  },
  deleteWishList (id) {
    return Vue.axios.post(baseUrl + `user/wishlist/${id}/delete`, {
      timeout: timeout
    })
  },
  otherTravellers () {
    return Vue.axios.get(baseUrl + 'user/relatives',
      {
        timeout: timeout,
        //   headers: { Accept: 'application/json', 'Content-type': 'application/json' },
        data: null
      }
    )
  },
  addTraveller (data) {
    return Vue.axios.post(baseUrl + 'user/relative/create', data, {
      timeout: timeout
    })
  },
  updateTraveller (data) {
    return Vue.axios.post(baseUrl + 'user/relative/update', data, {
      timeout: timeout
    })
  },
  deleteTraveller (id) {
    return Vue.axios.delete(baseUrl + `user/relative/${id}/delete`, {
      timeout: timeout
    })
  },
  socialNetworks () {
    return Vue.axios.get(baseUrl + 'user/socialnetworks',
      {
        timeout: timeout,
        //   headers: { Accept: 'application/json', 'Content-type': 'application/json' },
        data: null
      }
    )
  },
  addSocialNetwork (data) {
    return Vue.axios.post(baseUrl + 'user/socialnetworks/add', data, {
      timeout: timeout
    })
  },
  editSocialNetwork (data, id) {
    return Vue.axios.post(baseUrl + `user/socialnetworks/${id}/edit`, data, {
      timeout: timeout
    })
  },
  deleteSocialNetwork (id) {
    return Vue.axios.delete(baseUrl + `user/socialnetworks/${id}/delete`, {
      timeout: timeout
    })
  },
  userInterests () {
    return Vue.axios.get(baseUrl + 'user/interests/answers',
      {
        timeout: timeout,
        //   headers: { Accept: 'application/json', 'Content-type': 'application/json' },
        data: null
      }
    )
  },
  interestQuestions () {
    return Vue.axios.get(baseUrl + 'user/interests/questions',
      {
        timeout: timeout,
        //   headers: { Accept: 'application/json', 'Content-type': 'application/json' },
        data: null
      }
    )
  },
  addInterest (data, id) {
    return Vue.axios.post(baseUrl + `user/interests/${id}/add`, data, {
      timeout: timeout
    })
  },
  editInterest (data, id) {
    return Vue.axios.post(baseUrl + `user/interests/${id}/update`, data, {
      timeout: timeout
    })
  },
  deleteInterest (id) {
    return Vue.axios.delete(baseUrl + `user/interests/${id}/delete`, {
      timeout: timeout
    })
  },
  userPreferences () {
    return Vue.axios.get(baseUrl + 'user/preferences/answers',
      {
        timeout: timeout,
        //   headers: { Accept: 'application/json', 'Content-type': 'application/json' },
        data: null
      }
    )
  },
  preferenceQuestions () {
    return Vue.axios.get(baseUrl + 'user/preferences/questions',
      {
        timeout: timeout,
        //   headers: { Accept: 'application/json', 'Content-type': 'application/json' },
        data: null
      }
    )
  },
  addPreference (data, id) {
    return Vue.axios.post(baseUrl + `user/preferences/${id}/add`, data, {
      timeout: timeout
    })
  },
  editPreference (data, id) {
    return Vue.axios.post(baseUrl + `user/preferences/${id}/update`, data, {
      timeout: timeout
    })
  },
  deletePreference (id) {
    return Vue.axios.delete(baseUrl + `user/preferences/${id}/delete`, {
      timeout: timeout
    })
  },

  // countries
  countries () {
    return Vue.axios.get(baseUrl + 'list-countries',
      {
        timeout: timeout,
        //   headers: { Accept: 'application/json', 'Content-type': 'application/json' },
        data: null
      }
    )
  },

  // cities by country
  cities (countryCode) {
    return Vue.axios.get(baseUrl + 'countries/' + countryCode + '/list-cities',
      {
        timeout: timeout,
        //   headers: { Accept: 'application/json', 'Content-type': 'application/json' },
        data: null
      }
    )
  },

  // active trips
  activeTrips () {
    return Vue.axios.get(baseUrl + 'user/trips',
      {
        timeout: timeout
      })
  },
  trip (id) {
    return Vue.axios.get(baseUrl + 'user/trips/' + id,
      {
        timeout: timeout
      })
  },
  tripTravellers (id) {
    return Vue.axios.get(baseUrl + 'user/trips/' + id + '/travellers',
      {
        timeout: timeout
      })
  },

  // trips bookings
  tripBookings (num) {
    return Vue.axios.get(baseUrl + `user/trips-bookings?number_of_bookings=${num}`,
      {
        timeout: timeout
      })
  },

  // user memories
  userMemories (num) {
    return Vue.axios.get(baseUrl + `user/trips-memories?number_of_memories=${num}`,
      {
        timeout: timeout
      })
  },

  // friend suggestions
  friendSuggestions () {
    return Vue.axios.get(baseUrl + 'user/suggest-friends',
      {
        timeout: timeout
      })
  },

  // trips book
  tripBook (payload) {
    return Vue.axios.post(baseUrl + 'user/trips/book', payload,
      {
        timeout: timeout
      })
  },

  // memory images
  addMemoryImages (payload, id) {
    return Vue.axios.post(baseUrl + `user/trips-memories/${id}/add-image`, payload,
      {
        timeout: 60000
      })
  },

  // friends
  getFriends () {
    return Vue.axios.get(baseUrl + 'user/friends',
      {
        timeout: timeout
      })
  },

  friendDetails (id) {
    return Vue.axios.get(baseUrl + `user/friend-details/${id}`,
      {
        timeout: timeout
      })
  },

  suggestedFriends () {
    return Vue.axios.get(baseUrl + 'user/suggest-friends',
      {
        timeout: timeout
      })
  },

  pendingFriends () {
    return Vue.axios.get(baseUrl + 'user/pending-friends',
      {
        timeout: timeout
      })
  },

  friendsRequests () {
    return Vue.axios.get(baseUrl + 'user/friends-requests',
      {
        timeout: timeout
      })
  },

  addFriend (id) {
    return Vue.axios.get(baseUrl + `user/add-friend/${id}`,
      {
        timeout: timeout
      })
  },

  confirmFriend (id) {
    return Vue.axios.get(baseUrl + `user/confirm-friend/${id}`,
      {
        timeout: timeout
      })
  },

  cancelFriend (id) {
    return Vue.axios.get(baseUrl + `user/cancel-friend/${id}`,
      {
        timeout: timeout
      })
  },

  // tags
  tags () {
    return Vue.axios.get(baseUrl + 'tags',
      {
        timeout: timeout
      })
  },
  directTags () {
    return Vue.axios.get(baseUrl + 'user/direct-tags',
      {
        timeout: timeout
      })
  },
  assignTags (body) {
    return Vue.axios.post(baseUrl + 'user/assign-tag', body, { timeout: timeout })
  },
  removeTags (body) {
    return Vue.axios.post(baseUrl + 'user/remove-tag', body, { timeout: timeout })
  }
}
