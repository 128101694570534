<template>
    <div class="my-15">
        <h2 class="primary--text text-center mb-5">Your Packages</h2>
        <v-row justify="center" v-if="!loading && packages.length">
          <v-col cols="12" sm="6" :md="i === 0 || i === 1 ? 6 : 4" v-for="(item,  i) in packages" :key="item.id">
            <v-card @click="$store.dispatch('setAgencyTrip', item); $router.push({name: 'tripDetails', params: {id: item.id}});">
                <v-img
                :src="item.image"
                lazy-src="https://picsum.photos/id/11/10/6"
                height="200"
                ></v-img>

                <v-card-title class="paragraph--text" v-text="item.name"></v-card-title>

                <v-card-text>
                  <p class="">{{item.description}}</p>
                    <v-row justify="space-between" align="center" class="px-3">
                        <div>
                            <v-icon>mdi-map</v-icon>
                            <span v-text="item.city"></span>
                        </div>
                        <p class="primaryDark--text text-bold mb-0">{{item.price_per_person | currency}}<small>/person</small></p>
                    </v-row>
                </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-btn elevation="0" @click="$router.push({name: 'userTrips'})" color="primary" x-large class="rounded-lg d-block mx-auto">show more</v-btn>
          </v-col>
        </v-row>
        <p v-else class="text-center primary--text">No packages yet!</p>
    </div>
</template>

<script>
import user from '@/web_services/user'

export default {
  data () {
    return {
      packages: [],
      loading: true
    }
  },
  methods: {},
  created () {
    user.activeTrips().then(res => {
      this.packages = res.data.data.slice(0, 5)
    }).catch(() => {
      this.packages = []
    }).finally(() => {
      this.loading = false
    })
  }
}
</script>
