<template>
    <div>
        <v-divider class="mt-0 mb-5"></v-divider>
        <v-container>
            <v-row justify="space-between">
                <v-col cols="12" sm="6" md="3" class="pr-5">
                    <img class="d-block logo-img" :src="require('@/assets/logo.png')" alt="Group Trip">

                    <p class="footer-text">Find the trip for you!</p>

                    <div class="d-flex">
                        <p class="primaryDark--text text-bold">Follow Us :</p>
                        <a href="" class="mx-2">
                            <v-icon color="primaryDark">mdi-facebook</v-icon>
                        </a>
                        <a href="" class="mx-2">
                            <v-icon color="primaryDark">mdi-linkedin</v-icon>
                        </a>
                        <a href="" class="mx-2">
                            <v-icon color="primaryDark">mdi-instagram</v-icon>
                        </a>
                        <a href="" class="mx-2">
                            <v-icon color="primaryDark">mdi-google-plus</v-icon>
                        </a>
                    </div>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="mt-3">
                    <p class="footer-heading primaryDark--text text-bold">Easy Access</p>
                    <router-link class="footer-text d-block my-3" v-for="item in easyAccessItems" :key="item.label" v-text="item.label" :to="item.route"></router-link>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="mt-3">
                    <p class="footer-heading primaryDark--text text-bold">Legal & Rewards</p>
                    <router-link class="footer-text d-block my-3" v-for="item in legalsItems" :key="item.label" v-text="item.label" :to="item.route"></router-link>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="mt-3">
                    <p class="footer-heading primaryDark--text text-bold">Contact Us</p>
                    <a href="tel:(+1) 305-567-3985" class="footer-text d-block my-3"><v-icon color="primaryDark">mdi-phone</v-icon> (+1) 305-567-3985</a>
                    <a href="mailto:info@grouptrip.com" class="footer-text d-block my-3"><v-icon color="primaryDark">mdi-email</v-icon> info@grouptrip.com</a>
                </v-col>
            </v-row>
        </v-container>
        <p class="primaryDark text-center white--text mb-0">© {{ new Date().getFullYear() }} Group Trip All Rights Reserved, Powered By RASN Consult.</p>
    </div>
</template>

<script>
export default {
  data () {
    return {
      easyAccessItems: [
        { label: 'About', route: '/about' },
        { label: 'Flights', route: '/flights' },
        { label: 'Stays', route: '/stays' },
        { label: 'Cars', route: '/cars' }
        // { label: 'Customer Service', route: '' }
      ],
      legalsItems: [
        { label: 'Terms And Conditions', route: '' },
        { label: 'Privacy Policy', route: '' }
        // { label: 'Rewards', route: '' }
      ]
    }
  }
}
</script>

<style>
.footer-text {
    color: #707070 !important;
    font-size: small;
}
.footer-heading {
    position: relative;
}
.footer-heading::after {
    content: '';
    height: 2px;
    width: 20%;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #FF8F95;
}
</style>
