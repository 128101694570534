import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import VueAxios from 'vue-axios'
import './assets/style/main.css'
import VueCookies from 'vue-cookies'
import * as VueGoogleMaps from 'vue2-google-maps'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import { VueMasonryPlugin } from 'vue-masonry'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import VueSocialSharing from 'vue-social-sharing'
import { initFbsdk } from '@/config/facebook_oAuth.js'
import GoogleAuth from '@/config/google_oAuth'
import Croppa from 'vue-croppa'
import 'vue-croppa/dist/vue-croppa.css'

const gauthOption = {
  clientId: '1025277975504-8qa2mnuqfvpl453g5on8quc9i3e7bsg1.apps.googleusercontent.com',
  scope: 'profile email',
  prompt: 'select_account'
}
initFbsdk()
Vue.use(GoogleAuth, gauthOption)
Vue.use(Croppa)

Vue.config.productionTip = false

Vue.component('date-picker', DatePicker)

axios.defaults.headers.common.Authorization = 'Bearer ' + VueCookies.get('gt_user_token')
axios.defaults.headers.common['Content-Type'] = 'application/json'

Vue.use(VueCookies)
Vue.use(VueAxios, axios)
Vue.use(VueSocialSharing)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBqiCuWuXz8g9T1yWQlja5hkFlw_FMiIwM',
    libraries: 'places'
  }
})

Vue.use(VueMasonryPlugin)

Vue.component('dateRange', DateRangePicker)

Vue.config.productionTip = false

Vue.filter('date', function (value) {
  if (!value) return ''
  // const date = new Date(value).toJSON()
  const day = new Date(value).toISOString().split('T')[0]
  const time = new Date(value).toTimeString()
  return day + ' ' + time.substring(0, 5)
})

Vue.filter('currency', function (value) {
  if (!value) return ''
  return value.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD'
  })
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
